import React from "react";
import {
  TableFooter as TableFooterPrimitive,
  TableFooterProps as TableFooterPrimitiveProps,
} from "@nemo-ui/design-system.ui.table";

interface TableFooterProps extends TableFooterPrimitiveProps {}

export const TableFooter = (props: TableFooterProps) => {
  return (
    <TableFooterPrimitive {...props}>{props.children}</TableFooterPrimitive>
  );
};
