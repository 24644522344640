import React from "react";
import styles from "./ObserverDetailsOverviewCard.module.css";

interface ObserverDetailsOverviewCardProps {
  name: string;
  value: number;
  description: string;
  unit: string;
}

export const ObserverDetailsOverviewCard = ({
  name,
  value,
  description,
  unit,
}: ObserverDetailsOverviewCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{name}</div>
      <div className={styles.valuesContainer}>
        <div className={styles.value}>
          {value}
          <span className={styles.span}>{unit}</span>
        </div>

        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};
