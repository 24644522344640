import React from "react";
import {
  ButtonLabel as ButtonLabelPrimary,
  ButtonLabelProps as ButtonLabelPrimaryProps,
} from "@nemo-ui/design-system.ui.button";

interface ButtonLabelProps extends ButtonLabelPrimaryProps {
  children?: string;
  className?: string;
}

export const ButtonLabel = (props: ButtonLabelProps) => {
  return (
    <ButtonLabelPrimary className={props.className} {...props}>
      {props.children}
    </ButtonLabelPrimary>
  );
};
