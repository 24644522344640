import React from "react";
import {
  TableHeader as TableHeaderPrimitive,
  TableHeaderProps as TableHeaderPrimitiveProps,
} from "@nemo-ui/design-system.ui.table";

interface TableHeaderProps extends TableHeaderPrimitiveProps {}

export const TableHeader = (props: TableHeaderProps) => {
  return (
    <TableHeaderPrimitive {...props}>{props.children}</TableHeaderPrimitive>
  );
};
