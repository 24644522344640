import React, { useState } from "react";
import {
  OrderedSchemaObserverCreationSteps,
  OrderedStep,
  SchemaObserverCreationFormData,
} from "./types";
import { ObserverType, StateType } from "types";
import { SubmitHandler } from "react-hook-form";
import { Alert } from "../../_ui";
import {
  ObserverHeaderCreation,
  ObserverInfoForm,
  ObserverSchedulerForm,
  ProgressStepper,
} from "components";
import { useCreateSchemaObserver } from "services";
import styles from "./ObserverSchemaCreationContainer.module.css";
import { useNavigate } from "react-router-dom";

export const ObserverSchemaCreationContainer = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const { mutate } = useCreateSchemaObserver();
  const [currentStep, setCurrentStep] = useState<OrderedStep>(
    OrderedSchemaObserverCreationSteps[0],
  );
  const [formData, setFormData] = useState<SchemaObserverCreationFormData>({
    name: "",
    tableName: "",
    description: "",
    resourceName: "",
    state: StateType.ENABLED,
    alertDetectionPeriod: { unit: null, value: 0 },
    startingTimeScheduler: { value: 0, unit: null, date: "" },
  });

  const onSubmit: SubmitHandler<SchemaObserverCreationFormData> = async (
    data,
  ) => {
    const formDataWithObserverType = {
      ...data,
      type: ObserverType.SCHEMA,
    };
    mutate(formDataWithObserverType, {
      onSuccess: (response) => {
        setAlertType("success");
        setAlertMessage("Observer creation successful!");
        setShowAlert(true);
        setTimeout(() => {
          navigate("/observers");
        }, 3000);
      },
      onError: (error) => {
        console.error("Error:", error);
        setAlertType("error");
        setAlertMessage("Error occurred during observer creation.");
        setShowAlert(true);
      },
    });
  };

  const handleOnPreviousStep = () => {
    setCurrentStep(OrderedSchemaObserverCreationSteps[currentStep.step - 2]);
  };

  const handleSubmitStep = (
    stepData: Partial<SchemaObserverCreationFormData>,
  ) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, ...stepData };

      if (currentStep.step === OrderedSchemaObserverCreationSteps.length) {
        onSubmit(updatedData);
      } else {
        setCurrentStep(OrderedSchemaObserverCreationSteps[currentStep.step]);
      }

      return updatedData;
    });
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading_wrapper}>
        <ObserverHeaderCreation name={"Create new schema observer"} />
      </div>

      <ProgressStepper
        steps={OrderedSchemaObserverCreationSteps}
        currentStep={currentStep.step}
        renderLabels={true}
      />
      {showAlert && (
        <Alert
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
        />
      )}
      {currentStep === OrderedSchemaObserverCreationSteps[0] && (
        <ObserverInfoForm
          onSubmit={(data) => handleSubmitStep(data)}
          formData={formData}
        />
      )}

      {currentStep === OrderedSchemaObserverCreationSteps[1] && (
        <ObserverSchedulerForm
          onSubmit={(data) => handleSubmitStep(data)}
          onClickBackButton={handleOnPreviousStep}
        />
      )}
    </div>
  );
};
