import React, { useCallback, useEffect, useRef, useState } from "react";
import { ActiveElement, Chart, ChartEvent } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { Card, Text } from "../../_ui";
import styles from "./VolumeObserverGraph.module.css";
import { VolumeStats, VolumeStatsCord } from "types";

interface VolumeObserverGraphProps {
  observerLogsStats: VolumeStats | undefined;
  isFetching: boolean;
  isLoading: boolean;
  onPointClick: (details: VolumeStatsCord) => void;
}

export const ObserverVolumeGraph: React.FC<VolumeObserverGraphProps> = ({
  observerLogsStats,
  isFetching,
  isLoading,
  onPointClick,
}) => {
  const graphRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [initialized, setInitialized] = useState(false);

  const handlePointClick = useCallback(
    (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const details = observerLogsStats!.volumeObserverStatsCord[index];
        onPointClick(details);
      }
    },
    [observerLogsStats, onPointClick],
  );

  useEffect(() => {
    if (
      observerLogsStats &&
      observerLogsStats.volumeObserverStatsCord &&
      graphRef.current
    ) {
      const ctx = graphRef.current.getContext("2d");
      if (ctx) {
        const volumeData = observerLogsStats.volumeObserverStatsCord.map(
          ({ executionDate, actualRowAdded }) => ({
            x: new Date(executionDate).getTime(),
            y: actualRowAdded,
          }),
        );

        const minExpectedRow = observerLogsStats.minExpectedRow;
        const maxExpectedRow = observerLogsStats.maxExpectedRow;

        const firstDate = volumeData[0].x;
        const lastDate = volumeData[volumeData.length - 1].x;

        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        chartInstanceRef.current = new Chart(ctx, {
          type: "line",
          data: {
            datasets: [
              {
                label: "Actual Rows Added",
                data: volumeData,
                borderColor: "#532A84",
              },
              {
                label: "Min Expected Row",
                data: [
                  { x: firstDate, y: minExpectedRow },
                  { x: lastDate, y: minExpectedRow },
                ],
                borderColor: "#FFC107",
                borderDash: [5, 5],
              },
              {
                label: "Max Expected Row",
                data: [
                  { x: firstDate, y: maxExpectedRow },
                  { x: lastDate, y: maxExpectedRow },
                ],
                borderColor: "#FFC107",
                borderDash: [5, 5],
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: "time",
                time: {
                  tooltipFormat: "PPp",
                },
                ticks: {
                  source: "auto",
                  callback: (value) => format(new Date(value), "PPp"),
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Number of Rows",
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const dataPoint = volumeData[context.dataIndex];
                    return `Rows: ${dataPoint.y} on ${format(
                      new Date(dataPoint.x),
                      "PPp",
                    )}`;
                  },
                },
              },
            },
            animation: initialized ? { duration: 0 } : { duration: 2000 },
            onClick: handlePointClick,
          },
        });

        setInitialized(true);

        return () => {
          chartInstanceRef.current?.destroy();
        };
      }
    }
  }, [observerLogsStats, handlePointClick, initialized]);

  if (isLoading) {
    return (
      <div>
        <Card>
          <div>
            <Text size={"lg"}>Loading volume chart...</Text>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.graphContainer}>
      <div className={styles.card}>
        <div className={styles.title}></div>
        {!isLoading &&
        (!observerLogsStats ||
          !observerLogsStats.volumeObserverStatsCord ||
          observerLogsStats.volumeObserverStatsCord.length === 0) ? (
          <div>
            <Text size={"lg"}>No runs in this period.</Text>
          </div>
        ) : (
          <div className={styles.body}>
            <canvas ref={graphRef} height={150}></canvas>
          </div>
        )}
      </div>
    </div>
  );
};
