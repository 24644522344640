import React from "react";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./utils";
import { Button, ButtonLabel, Form, FormFieldInput } from "../../../_ui";
import { VolumeObserverRuleFormData } from "./types";
import styles from "./ObserverVolumeRuleForm.module.css";
import { VolumeObserverCreationFormData } from "../../../containers/ObserverVolumeCreationContainer/types";

interface Props {
  onSubmit: (data: VolumeObserverRuleFormData) => void;
  onClickBackButton: () => void;
  formData?: VolumeObserverCreationFormData;
}

export const ObserverVolumeRuleForm: React.FC<Props> = ({
  onSubmit,
  onClickBackButton,
  formData,
}) => {
  // Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<VolumeObserverRuleFormData>({
    resolver: yupResolver(schema) as Resolver<VolumeObserverRuleFormData>,
    defaultValues: formData,
  });

  // Event handlers
  const handleOnSubmit = (data: VolumeObserverRuleFormData) => {
    onSubmit(data);
  };
  const handleBack = () => {
    onClickBackButton();
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"timestamp"}
            label={"Timestamp"}
            fullWidth
            errorMessage={errors.timestamp?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"maxRowExpected"}
            label={"Max expecting row"}
            type={"number"}
            errorMessage={errors.maxRowExpected?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"minRowExpected"}
            label={"Min expecting row"}
            type={"number"}
            errorMessage={errors.minRowExpected?.message}
          />
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.buttonGost} onClick={handleBack}>
            <ButtonLabel>Back</ButtonLabel>
          </button>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Form>
  );
};
