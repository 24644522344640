import React from "react";
import styles from "../ObserverSearchSection/SearchSection.module.css";
import { Button, Searchbar, Select } from "../../_ui";
import { ResourceType } from "../../types";

interface Props {
  filters: {
    searchQuery: string;
  };
  onChange: (
    newFilters: Partial<{
      searchQuery: string;
      searchType: string;
    }>,
  ) => void;
  handleReset: () => void;
}

export const ResourceSearchSection: React.FC<Props> = ({
  filters,
  onChange,
  handleReset,
}) => {
  const handleSearchQueryChange = (value: string) =>
    onChange({ searchQuery: value });
  const handleSearchTypeChange = (value: string) =>
    onChange({ searchType: value });

  return (
    <div className={styles.searchSection}>
      <Searchbar
        value={filters.searchQuery}
        onChange={handleSearchQueryChange}
        placeholder="Search by name"
        className={styles.input}
        fullWidth={false}
      />
      <Select
        className={styles.select}
        variant={"outlined"}
        defaultValue={""}
        onChange={handleSearchTypeChange}
        options={Object.values(ResourceType).map((unit) => ({
          value: unit,
          label: unit,
        }))}
        placeholder="Type"
      />
      <Button
        variant={"neutral"}
        onClick={handleReset}
        className={styles.reset}
      >
        Reset
      </Button>
    </div>
  );
};
