import React from "react";
import styles from "./ObserverDetailsTabsSwitch.module.css";
import { useSearchParams } from "react-router-dom";
import { TabItem, Tabs } from "../../_ui";
import {
  ObserverDetailsContainer,
  ObserverDetailsRunsListingContainer,
} from "../index";

interface ObserverDetailsTabsSwitchProps {
  className?: string;
}

export const ObserverDetailsTabsSwitchContainer = (
  props: ObserverDetailsTabsSwitchProps,
) => {
  const [params, setParams] = useSearchParams();
  const [showOverview, setShowOverview] = React.useState(true);
  const [showRuns, setShowRuns] = React.useState(false);
  const handleClickObservers = () => {
    setShowRuns(false);
    setShowOverview(true);
  };
  const handleClickRuns = () => {
    params.delete("page");
    setParams(params);
    setShowRuns(true);
    setShowOverview(false);
  };
  return (
    <div className={props.className}>
      <Tabs className={styles.tabs}>
        <TabItem
          label={"Overview"}
          onClick={handleClickObservers}
          active={showOverview}
        />
        <TabItem label={"Runs"} onClick={handleClickRuns} active={showRuns} />
      </Tabs>
      {showOverview && (
        <ObserverDetailsContainer setShowRuns={handleClickRuns} />
      )}
      {showRuns && <ObserverDetailsRunsListingContainer />}
    </div>
  );
};
