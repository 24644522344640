import { useQuery } from "react-query";
import { ClientApi } from "services/index";
import { API_ENDPOINTS, QUERY_KEYS } from "config";
import { ObserverDetailsStatistics, ObserverStatsParams } from "types";

interface Props {
  params: ObserverStatsParams;
}

export const useGetObserversStats = ({ params }: Props) => {
  return useQuery<ObserverDetailsStatistics>({
    queryKey: [QUERY_KEYS.logsStats, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObserverLogsStats(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
