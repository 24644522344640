import { Criteria } from "./api";

export enum ObserverType {
  FRESHNESS = "FRESHNESS",
  VOLUME = "VOLUME",
  SCHEMA = "SCHEMA",
  DATAQUALITY = "DATAQUALITY",
}

export type AlertDetectionPeriod = {
  value: number;
  unit: AlertUnit | null;
};

export type TimeScheduler = {
  date: string;
  value: number;
  unit: TimeUnit | null;
};

export enum TimeUnit {
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
}

export enum AlertUnit {
  MONTHS = "MONTHS",
  HOURS = "HOURS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
}

export interface ObserverCriteria extends Criteria {
  name?: string;
  type?: ObserverType;
  tableName?: string;
  state?: StateType;
}

export enum StatusType {
  FAILED = "FAILED",
  PASSED = "PASSED",
  WARNING = "WARNING",
  RUNNING = "RUNNING",
}

export enum StateType {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export interface ObserverPageDto {
  id: string;
  name: string;
  type: ObserverType;
  tableName: string;
  startingTimeScheduler: TimeScheduler;
  state: StateType;
  source: string;
  failedRuns: number;
  passedRuns: number;
  lastRunTime: Date;
  lastRun: string;
  nextRun: string;
}

export interface ObserverStatistics {
  volumeObserverNumber: number;
  schemaObserverNumber: number;
  dataQualityObserverNumber: number;
  freshnessObserverNumber: number;
  dataSourceNumber: number;
  tablesNumber: number;
  failedVolumeObserverNumber: number;
  failedSchemaObserverNumber: number;
  failedDataQualityObserverNumber: number;
  failedFreshnessObserverNumber: number;
}

export interface ObserverRepresentation {
  id: string;
  name: string;
  type: ObserverType;
  tableName: string;
  description: string;
  startingTimeScheduler: TimeScheduler;
  alertDetectionPeriod: AlertDetectionPeriod;
  state: StateType;
  resourceName: string;
}

export interface FreshnessRepresentation extends ObserverRepresentation {
  timestamp: string;
}

export interface ObserverFilters {
  searchQuery: string;
  searchType: string;
  searchTableName: string;
  searchStateType: string;
}
