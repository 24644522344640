import React from "react";
import { getUser, useAuth } from "auth";
import styles from "./AuthItem.module.css";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
  MenuItemLink,
} from "_ui";

interface AuthItemProps {
  isSideBarOpen: boolean;
}

export function AuthItem(props: AuthItemProps) {
  const { isAuthenticated, signoutRedirect, signinRedirect } = useAuth();
  const user = getUser();
  const handleButtonClick = () => {
    isAuthenticated ? signoutRedirect() : signinRedirect();
  };

  return (
    <MenuItem className={styles.menu} key={"log"}>
      <NavLink
        to="#"
        style={{ width: "100%" }}
        className={styles.navLink}
        onClick={handleButtonClick}
      >
        {isAuthenticated && user && props.isSideBarOpen && (
          <MenuItemLink>
            <MenuItemIcon>
              <Avatar name={user.profile.name as string} size={20} />
            </MenuItemIcon>
            <MenuItemLabel>
              {isAuthenticated ? "Logout" : "Login"}
            </MenuItemLabel>
          </MenuItemLink>
        )}
      </NavLink>
    </MenuItem>
  );
}
