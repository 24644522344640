import React from "react";
import styles from "./ObserverRunDetailsCard.module.css";
import { ObserverStats } from "types";
import { formatDate } from "../../utils";
import {
  Badge,
  FailedRunIcon,
  PassedRunIcon,
  RunningIcon,
  WarningIcon,
} from "../../_ui";

interface ObserverRunDetailsCardProps {
  runDetails: ObserverStats | null;
}

const runsIcons: { [key: string]: JSX.Element } = {
  FAILED: (
    <Badge
      icon={<FailedRunIcon color={"#FB2942"} />}
      text="Failed"
      backgroundColor="#F7F7F8"
      color="#FB2942"
    />
  ),
  PASSED: (
    <Badge
      icon={<PassedRunIcon color={"#59BE48"} />}
      text="Passed"
      color="#59BE48"
      backgroundColor="#F7F7F8"
    />
  ),
  WARNING: (
    <Badge
      icon={<WarningIcon color={"#EFDD3B"} />}
      text="Warning"
      color="#EFDD3B"
      backgroundColor="#F7F7F8"
    />
  ),
  RUNNING: (
    <Badge
      icon={<RunningIcon color={"#FF9800"} />}
      text="Running"
      color="#FF9800"
      backgroundColor="#F7F7F8"
    />
  ),
};

export const ObserverRunDetailsCard: React.FC<ObserverRunDetailsCardProps> = ({
  runDetails,
}) => {
  if (!runDetails) {
    return <div>No runs in the selected period.</div>;
  }

  return (
    <div className={styles.card}>
      <div className={styles.title}>Run details</div>
      <div className={styles.body}>
        <div className={styles.descSection}>
          <div className={styles.titleDesc}>Description</div>
          <div className={styles.description}>{runDetails.description}</div>
        </div>
        <div className={styles.statusSection}>
          <div className={styles.titleDesc}>Status</div>
          <div className={styles.statusResult}>
            {runsIcons[runDetails.status] &&
              React.cloneElement(runsIcons[runDetails.status])}
          </div>
        </div>
        <div className={styles.timeSection}>
          <div className={styles.titleDesc}>Running time</div>
          <div className={styles.time}>
            {runDetails.runningTime == null ? 0 : runDetails.runningTime}{" "}
            seconds
          </div>
        </div>
        <div className={styles.dateSection}>
          <div className={styles.titleDesc}>Ran on</div>
          <div className={styles.date}>
            {formatDate(runDetails.executionDate)}
          </div>
        </div>
      </div>
    </div>
  );
};
