import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";

export const useDeleteObserver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteObserver],
    mutationFn: (observerId: string) =>
      ClientApi.delete(API_ENDPOINTS.deleteObserver(observerId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.observers]);
    },
  });
};
