import React from "react";
import { ObserverDetailHeader } from "../../components";
import { useGetObserver } from "services";
import { useParams } from "react-router-dom";

export const ObserverDetailsHeaderContainer = () => {
  const { observerId } = useParams();
  const { data: observer } = useGetObserver(observerId as string);
  return (
    <div>
      <ObserverDetailHeader
        type={observer?.type}
        state={observer?.state}
        source={observer?.resourceName}
        name={observer?.name}
      />
    </div>
  );
};
