import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const QupidSymbol = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="32"
        height="26"
        viewBox="0 0 32 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          d="M22.5039 11.6142C22.5039 17.7372 17.5403 22.7008 11.4173 22.7008C5.29436 22.7008 0.330719 17.7372 0.330719 11.6142C0.330719 5.49123 5.29436 0.527588 11.4173 0.527588C17.5403 0.527588 22.5039 5.49123 22.5039 11.6142Z"
          fill="#DCBFFF"
        />
        <path
          d="M31.3228 21.063C31.3228 23.4983 29.3487 25.4725 26.9134 25.4725C24.4781 25.4725 22.5039 23.4983 22.5039 21.063C22.5039 18.6278 24.4781 16.6536 26.9134 16.6536C29.3487 16.6536 31.3228 18.6278 31.3228 21.063Z"
          fill="#DCBFFF"
        />
      </svg>
    );
  },
);
