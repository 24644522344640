import React from "react";
import {
  DataQualityIcon,
  FreshnessIcon,
  SchemaIcon,
  VolumeIcon,
} from "../../_ui";

export interface CardContent {
  key: string;
  title: string;
  icon: React.ReactElement;
  firstPhrase: string;
  secondPhrase: string;
  containerLink: string;
}

export interface ObserverGroup {
  groupTitle: string;
  groupDescription: string;
  types: CardContent[];
}

export const CardContentCreation: ObserverGroup[] = [
  {
    groupTitle: "Data quality observer",
    groupDescription: "Evaluates the quality and accuracy of data.",
    types: [
      {
        key: "UNIQUENESS_OBSERVER",
        title: "Uniqueness ",
        icon: <DataQualityIcon />,
        firstPhrase:
          "Monitors the uniqueness of data within specified data sources.",
        secondPhrase: "It helps ensure that no duplicate data exists.",
        containerLink: "quality/uniqueness",
      },
      {
        key: "COMPLETENESS_OBSERVER",
        title: "Completeness ",
        icon: <DataQualityIcon />,
        firstPhrase:
          "Tracks the completeness of data within specified data sources.",
        secondPhrase:
          "It helps ensure that all expected data fields are present.",
        containerLink: "quality/completeness",
      },
      {
        key: "PATTERN_MATCH_OBSERVER",
        title: "Pattern match ",
        icon: <DataQualityIcon />,
        firstPhrase:
          "Evaluates data within designated data sources for pattern matches.",
        secondPhrase: "It helps identify and handle data anomalies.",
        containerLink: "quality/pattern-match",
      },
      {
        key: "COMPLIANCE_OBSERVER",
        title: "Compliance ",
        icon: <DataQualityIcon />,
        firstPhrase:
          "Monitors data within specified data sources for compliance.",
        secondPhrase: "It ensures that data adheres to regulatory standards.",
        containerLink: "quality/compliance",
      },
    ],
  },
  {
    groupTitle: "Freshness observer",
    groupDescription: "Tracks the freshness or recency of data.",
    types: [
      {
        key: "FRESHNESS_OBSERVER",
        title: "freshness ",
        icon: <FreshnessIcon />,
        firstPhrase:
          "Tracks the freshness or recency of data within specified data sources.",
        secondPhrase: "It helps ensure that your data is up-to-date.",
        containerLink: "freshness",
      },
    ],
  },
  {
    groupTitle: "Volume observer",
    groupDescription: "Monitors the volume or quantity of data.",
    types: [
      {
        key: "VOLUME_OBSERVER",
        title: "volume ",
        icon: <VolumeIcon />,
        firstPhrase:
          "Monitors the volume or quantity of data within specified data sources.",
        secondPhrase:
          "It helps ensure that expected data volumes are maintained.",
        containerLink: "volume",
      },
    ],
  },
  {
    groupTitle: "Schema observer",
    groupDescription: "Monitors the structure and schema of data.",
    types: [
      {
        key: "SCHEMA_OBSERVER",
        title: "schema",
        icon: <SchemaIcon />,
        firstPhrase:
          "Monitors the structure and schema of data within specified data sources.",
        secondPhrase: "It ensures that data conforms to expected schemas.",
        containerLink: "schema",
      },
    ],
  },
];
