import React, { forwardRef, TextareaHTMLAttributes } from "react";
import clsx from "clsx";
import styles from "./TextArea.module.css";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ fullWidth = false, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={clsx(
          "nemo-text-area",
          styles.wrapper,
          fullWidth && styles.fullWidth,
        )}
        {...props}
      />
    );
  },
);
