import React, { useState } from "react";
import {
  AlertDialog,
  Badge,
  DotsHorizontalIcon,
  FailedRunIcon,
  PassedRunIcon,
  Popover,
  TableCell,
  TableRow,
} from "_ui";
import styles from "./ObserversDataTable.module.css";
import { formatDate, observerIcons, observerState } from "utils";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { ObserverPageDto, ObserverType } from "types";

interface ObserversDataTableRowProps {
  observer: ObserverPageDto;
  handleUpdate: (observerId: string) => void;
  handleDelete: (observerId: string) => void;
  onObserverRowClick: (observerId: string, type: ObserverType) => void;
}

export const ObserversDataTableRow = ({
  observer,
  handleUpdate,
  handleDelete,
  onObserverRowClick,
}: ObserversDataTableRowProps) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // State to manage dialog visibility

  const handleObserverClicked = () => {
    onObserverRowClick(observer.id, observer.type);
  };

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const confirmDelete = () => {
    handleDelete(observer.id);
    closeDeleteDialog();
  };

  return (
    <TableRow>
      <TableCell onClick={handleObserverClicked}>
        {observerIcons[observer.type] &&
          React.cloneElement(observerIcons[observer.type])}
      </TableCell>

      <TableCell onClick={handleObserverClicked}>
        <span className={styles.name}>{observer.name.toUpperCase()}</span>
      </TableCell>

      <TableCell onClick={handleObserverClicked}>
        {observerState[observer.state] &&
          React.cloneElement(observerState[observer.state])}
      </TableCell>

      <TableCell className={styles.source} onClick={handleObserverClicked}>
        {observer.source.toLowerCase()}
      </TableCell>

      <TableCell onClick={handleObserverClicked}>
        {observer.failedRuns !== 0 ? (
          <Badge
            icon={<FailedRunIcon color={"#FB2942"} />}
            text={`${observer.failedRuns} failed runs`}
            backgroundColor="#FFFFFF"
            color="#FB2942"
          />
        ) : (
          <div>-</div>
        )}
      </TableCell>

      <TableCell onClick={handleObserverClicked}>
        {observer.passedRuns !== 0 ? (
          <Badge
            icon={<PassedRunIcon color={"#59BE48"} />}
            text={`${observer.passedRuns} passed runs`}
            backgroundColor="#FFFFFF"
            color="#59BE48"
          />
        ) : (
          <div>-</div>
        )}
      </TableCell>

      <TableCell onClick={handleObserverClicked}>
        <div className={styles.container}>
          <div>
            <span className={styles.schedulers}>
              <p className={styles.title}>Next run: </p>{" "}
              {formatDate(observer.nextRun).toLowerCase()}
            </span>
          </div>
          <div>
            <span className={styles.schedulers}>
              <p className={styles.title}>Last run: </p>{" "}
              {formatDate(observer.lastRun).toLowerCase()}
            </span>
          </div>
        </div>
      </TableCell>

      <TableCell align={"right"}>
        <Popover trigger={<DotsHorizontalIcon />}>
          <ul className={styles.ul}>
            <li>
              <button
                onClick={() => handleUpdate(observer.id)}
                className={styles.updateButton}
              >
                <span>Update </span>
                <FaPenToSquare />
              </button>
            </li>
            <li>
              <button
                onClick={openDeleteDialog}
                className={styles.deleteButton}
              >
                <span>Delete </span>
                <RiDeleteBin5Fill />
              </button>
            </li>
          </ul>
        </Popover>
      </TableCell>
      <AlertDialog
        isOpen={showDeleteDialog}
        message={`By deleting '${observer.name}' you cannot see it's configuration or runs.`}
        onClose={closeDeleteDialog}
        onConfirm={confirmDelete}
      />
    </TableRow>
  );
};
