import React from "react";
import { ObserverUniquenessCreationContainer } from "containers";

export const ObserverUniquenessCreationPage = () => {
  return (
    <div>
      <ObserverUniquenessCreationContainer />
    </div>
  );
};
