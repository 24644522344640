import React from "react";
import { ROUTE } from "./types";
import {
  ObserverCompletenessCreationPage,
  ObserverCreationPage,
  ObserverDetailPage,
  ObserverFreshnessCreationPage,
  ObserverFreshnessUpdatePage,
  ObserverSchemaCreationPage,
  ObserversListingPage,
  ObserverUniquenessCreationPage,
  ObserverVolumeCreationPage,
  ObserverVolumeDetailsPage,
  ResourceCreationPage,
  ResourceListingPage,
} from "pages";

import { AppLayout } from "../layout/AppLayout";
import {
  ObserverComplianceCreationContainer,
  ObserverPatternMatchCreationContainer,
} from "../containers";

export const ROUTES: ROUTE[] = [
  {
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: "observers",
        crumb: "Observers",
        children: [
          {
            path: "",
            crumb: "Observer List",
            element: <ObserversListingPage />,
          },
          {
            path: ":observerId",
            crumb: "Observer details page",
            element: <ObserverDetailPage />,
          },
          {
            path: "volume",
            crumb: "Volume details",
            children: [
              {
                path: ":observerId",
                crumb: "Volume details page",
                element: <ObserverVolumeDetailsPage />,
              },
            ],
          },
          {
            path: "create",
            crumb: "create observer",
            children: [
              {
                path: "",
                crumb: "Observer creation page",
                element: <ObserverCreationPage />,
              },
              {
                path: "freshness",
                crumb: "freshness creation page",
                element: <ObserverFreshnessCreationPage />,
              },
              {
                path: "volume",
                crumb: "volume creation page",
                element: <ObserverVolumeCreationPage />,
              },
              {
                path: "schema",
                crumb: "schema creation page",
                element: <ObserverSchemaCreationPage />,
              },
              {
                path: "quality",
                crumb: "Quality creation page",
                children: [
                  {
                    path: "uniqueness",
                    crumb: "uniqueness creation page",
                    element: <ObserverUniquenessCreationPage />,
                  },
                  {
                    path: "completeness",
                    crumb: "completeness creation page",
                    element: <ObserverCompletenessCreationPage />,
                  },
                  {
                    path: "pattern-match",
                    crumb: "pattern match creation page",
                    element: <ObserverPatternMatchCreationContainer />,
                  },
                  {
                    path: "compliance",
                    crumb: "compliance creation page",
                    element: <ObserverComplianceCreationContainer />,
                  },
                ],
              },
            ],
          },
          {
            path: "update/:id",
            crumb: "Update Observer",
            element: <ObserverFreshnessUpdatePage />,
          },
        ],
      },
      {
        path: "resources",
        crumb: "resources",
        children: [
          {
            path: "",
            crumb: "resource List",
            element: <ResourceListingPage />,
          },
          {
            path: "create",
            crumb: "create resource",
            element: <ResourceCreationPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    crumb: "page not found",
    element: <div>404</div>,
  },
  {
    path: "/unauthorized",
    crumb: "unauthorized",
    element: <div>UnAuthorized</div>,
  },
];
