import React from "react";
import {
  ObserverDetailsHeaderContainer,
  ObserverDetailsVolumeTabsSwitchContainer,
} from "../../containers";

export const ObserverVolumeDetailsPage = () => {
  return (
    <div>
      <ObserverDetailsHeaderContainer />
      <ObserverDetailsVolumeTabsSwitchContainer />
    </div>
  );
};
