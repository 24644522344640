import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { FreshnessObserverCommandDto } from "types";

export const useCreateFreshnessObserver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createFreshnessObserver],
    mutationFn: (freshnessTestDto: FreshnessObserverCommandDto) => {
      return ClientApi.post(
        API_ENDPOINTS.createFreshnessObservers(),
        freshnessTestDto,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.observers], {
        exact: false,
      });
    },
  });
};
