import React from "react";
import { Outlet } from "react-router-dom";
import { AppShell, AppShellMain } from "@nemo-ui/design-system.ui.app-shell";
import styles from "./appLayout.module.css";
import { AppShellSidebar, QupidLogo, QupidSymbol } from "_ui";
import { AppSideMenu } from "../AppSideMenu";

export const AppLayout = () => {
  return (
    <AppShell>
      <AppShellSidebar
        className={styles.sideBar}
        renderBrandLogo={({ isDesktop, isOpen }) =>
          !isDesktop || isOpen ? <QupidLogo /> : <QupidSymbol />
        }
        renderContent={({ isOpen }) => <AppSideMenu isSideBarOpen={isOpen} />}
      />

      <AppShellMain>
        <div className={styles.wrapper}>
          <Outlet />
        </div>
      </AppShellMain>
    </AppShell>
  );
};
