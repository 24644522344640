import React from "react";
import { FreshnessObserverCreationContainer } from "containers";

export const ObserverFreshnessCreationPage = () => {
  return (
    <div>
      <FreshnessObserverCreationContainer />
    </div>
  );
};
