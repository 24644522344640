import React from "react";
import { TableRow as TableRowPrimitive } from "@nemo-ui/design-system.ui.table";

interface TableRowProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const TableRow = (props: TableRowProps) => {
  return <TableRowPrimitive {...props}>{props.children}</TableRowPrimitive>;
};
