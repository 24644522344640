import React from "react";
import { Option, Variant } from "../types";
import { ChevronDownIcon, Text } from "_ui";
import styles from "./SelectTrigger.module.css";
import clsx from "clsx";
import { UseSelectPropGetters } from "downshift";

interface SelectTriggerProps {
  selectedItem: Option | null;
  getToggleButtonProps: UseSelectPropGetters<Option>["getToggleButtonProps"];
  placeholder?: string;
  variant?: Variant;
}

export const SelectTrigger = ({
  selectedItem,
  getToggleButtonProps,
  placeholder,
  variant,
}: SelectTriggerProps) => {
  return (
    <div
      className={clsx(
        "nemo-select-trigger",
        styles.wrapper,
        variant === "outlined" && styles.outlined,
        variant === "ghost" && styles.ghost,
      )}
      {...getToggleButtonProps()}
    >
      <Text>{selectedItem ? selectedItem.label : placeholder}</Text>
      <div className={styles.arrow}>
        <ChevronDownIcon />
      </div>
    </div>
  );
};
