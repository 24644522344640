import { forwardRef } from "react";
import { IconProps } from "_ui";

export const FailedRunIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0ZM7.45 6.75L6.75 7.45L5 5.7L3.25 7.45L2.55 6.75L4.3 5L2.55 3.25L3.25 2.55L5 4.3L6.75 2.55L7.45 3.25L5.7 5L7.45 6.75Z"
          fill={color}
        />
      </svg>
    );
  },
);
