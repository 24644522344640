import React from "react";
import {
  MenuItemIcon as MenuItemIconPrimitive,
  MenuItemIconProps as MenuItemIconPrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuItemIconProps extends MenuItemIconPrimitiveProps {
  children?: React.ReactNode;
}

export const MenuItemIcon = (props: MenuItemIconProps) => {
  return (
    <MenuItemIconPrimitive {...props}>{props.children}</MenuItemIconPrimitive>
  );
};
