import React, { PropsWithChildren } from "react";
import { useSelect } from "downshift";
import { SelectOption } from "../SelectOption";
import { Option, Variant } from "../types";
import { SelectTrigger } from "../SelectTrigger";
import styles from "./Select.module.css";
import clsx from "clsx";

function itemToString(item: Option | null) {
  return item ? item.value : "";
}

interface SelectProps {
  defaultValue?: string;
  options: Option[];
  placeholder?: string;
  variant?: Variant;
  onChange: (value: string) => void;
  className?: string;
}

export const Select = ({
  placeholder,
  options,
  defaultValue,
  variant = "default",
  onChange,
  className,
}: PropsWithChildren<SelectProps>) => {
  // Hooks
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString,
    defaultSelectedItem: options.find(
      (option) => option.value === defaultValue,
    ),
    onSelectedItemChange: ({ selectedItem: selectedOption }) => {
      selectedOption && onChange(selectedOption.value);
    },
  });

  const optionsClassName = clsx(
    "nemo-select-options",
    styles.options_list,
    !isOpen && styles.hidden,
  );

  return (
    <div className={clsx("nemo-select", styles.wrapper, className)}>
      <SelectTrigger
        selectedItem={selectedItem}
        getToggleButtonProps={getToggleButtonProps}
        placeholder={placeholder}
        variant={variant}
      />
      <div className={styles.options_wrapper}>
        <ul className={optionsClassName} {...getMenuProps()}>
          {isOpen &&
            options.map((option, index) => (
              <SelectOption
                key={"option-" + index}
                selectedOption={selectedItem}
                option={option}
                getItemProps={getItemProps}
                index={index}
                highlightedIndex={highlightedIndex}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};
