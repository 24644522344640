import React from "react";
import { TabItem, Tabs } from "_ui";
import {
  ObserversListingContainer,
  RunsListingContainer,
} from "containers/index";
import styles from "./ObserverTabsSwitch.module.css";
import { useSearchParams } from "react-router-dom";

interface ObserverTabsSwitchProps {
  className?: string;
}

export const ObserverTabsSwitchContainer = (props: ObserverTabsSwitchProps) => {
  const [params, setParams] = useSearchParams();
  const [showObservers, setShowObservers] = React.useState(true);
  const [showRuns, setShowRuns] = React.useState(false);
  const handleClickObservers = () => {
    setShowRuns(false);
    setShowObservers(true);
  };
  const handleClickRuns = () => {
    params.delete("page");
    setParams(params);
    setShowRuns(true);
    setShowObservers(false);
  };
  return (
    <div className={props.className}>
      <Tabs className={styles.tabs}>
        <TabItem
          label={"Observers"}
          onClick={handleClickObservers}
          active={showObservers}
        />
        <TabItem label={"Runs"} onClick={handleClickRuns} active={showRuns} />
      </Tabs>
      {showObservers && <ObserversListingContainer />}
      {showRuns && <RunsListingContainer />}
    </div>
  );
};
