import React, { useEffect, useState } from "react";
import { ObserverLogDataTable, ObserverSearchSection } from "components";
import { ObserverFilters, ObserverType, StatusType } from "types";
import { useSearchParams } from "react-router-dom";
import { parsePageNumber } from "utils";
import { API_LOGS_PAGE_SIZE_DEFAULT } from "../../constants";
import { useGetObserverLogs } from "services";
import styles from "./RunsListingContainer.module.css";

const OBSERVER_SEARCH_CRITERIA_DEFAULT = {
  page: 0,
  size: API_LOGS_PAGE_SIZE_DEFAULT,
};

export const RunsListingContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilter] = useState<ObserverFilters>({
    searchQuery: "",
    searchType: "",
    searchTableName: "",
    searchStateType: "",
  });

  const currentPage = parsePageNumber(searchParams.get("page"));

  const {
    data: observerLogs,
    isLoading,
    isFetching,
  } = useGetObserverLogs({
    params: {
      ...OBSERVER_SEARCH_CRITERIA_DEFAULT,
      name: filters.searchQuery,
      type: filters.searchType as ObserverType,
      resourceName: filters.searchTableName,
      status: filters.searchStateType as StatusType,
      page: currentPage,
      sortBy: "timestamp",
      sortDirection: "DESC",
    },
  });

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  const handleChangeFilters = (newFilters: Partial<ObserverFilters>) => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };
  const handleOnReset = () => {
    setFilter({
      searchQuery: "",
      searchType: "",
      searchTableName: "",
      searchStateType: "",
    });
  };

  useEffect(() => {
    setSearchParams({
      page: (currentPage + 1).toString(),
      searchQuery: filters.searchQuery,
      searchType: filters.searchType,
      searchTableName: filters.searchTableName,
      searchStateType: filters.searchStateType,
    });
  }, [filters, currentPage, setSearchParams]);

  return (
    <div className={styles.container}>
      <ObserverSearchSection
        filters={filters}
        onChange={handleChangeFilters}
        typeSpecification={StatusType}
        typeOfSource="resource"
        handleReset={handleOnReset}
      />
      <ObserverLogDataTable
        observerLogs={observerLogs && observerLogs.content}
        isFetching={isFetching}
        isLoading={isLoading}
        currentPage={currentPage}
        totalPages={observerLogs && observerLogs.totalPages}
        totalElements={observerLogs && observerLogs.totalElements}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
};
