import clsx from "clsx";
import styles from "./ProgressBar.module.css";

interface ProgressBarProps {
  percent?: number;
  infinite?: boolean;
  position?: "absolute" | "relative";
  rounded?: boolean;
  color?: string;
}

export const ProgressBar = ({
  infinite = true,
  color,
  percent = 0,
  position = "relative",
  rounded = false,
}: ProgressBarProps) => {
  return (
    <div
      className={clsx(
        "nemo-progress-bar",
        styles.wrapper,
        infinite && styles.infinite,
        position === "absolute" && styles.absolute,
        rounded && styles.rounded,
      )}
    >
      <div
        className={clsx(
          "nemo-progress-bar-content",
          styles.content,
          rounded && styles.rounded,
        )}
        style={{
          width: infinite ? "100%" : `${percent}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  );
};
