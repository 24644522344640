import React from "react";
import { ObserverDetailsSideCard } from "../../components";
import { useGetObserverLog } from "../../services";

interface ObserverDetailsSideCardContainerProps {
  id?: string | undefined;
  setShowRuns?: (() => void) | undefined;
}

export const ObserverDetailsSideCardContainer = ({
  id,
  setShowRuns,
}: ObserverDetailsSideCardContainerProps) => {
  const {
    data: observerLog,
    isLoading,
    isFetching,
  } = useGetObserverLog({
    params: {
      sortBy: "timestamp" as string,
      size: 30,
      page: 0,
      sortDirection: "DESC",
    },
    observerId: id as string,
  });
  return (
    <div>
      <ObserverDetailsSideCard
        observerLog={observerLog?.content || []}
        isLoading={isLoading}
        isFetching={isFetching}
        setShowRuns={setShowRuns}
      />
    </div>
  );
};
