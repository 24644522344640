import React, { forwardRef } from "react";
import {
  Input as InputPrimitive,
  InputProps as InputPrimitiveProps,
} from "@nemo-ui/design-system.ui.input";

interface InputProps extends InputPrimitiveProps {}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    return <InputPrimitive {...props} ref={ref} />;
  },
);
