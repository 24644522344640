import { forwardRef } from "react";
import { IconProps } from "./types";

export const SearchIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          fillRule="evenodd"
          d="M10.574 16.757c-3.54 0-6.409-2.819-6.409-6.302 0-3.482 2.87-6.307 6.409-6.307 3.539 0 6.409 2.825 6.409 6.308 0 3.482-2.87 6.301-6.41 6.301Zm10.256 3.264-4.647-4.575a7.342 7.342 0 0 0 1.964-4.99c0-4.12-3.39-7.456-7.573-7.456S3 6.337 3 10.456c0 4.113 3.39 7.45 7.574 7.45a7.607 7.607 0 0 0 4.767-1.666l4.665 4.591a.587.587 0 0 0 .824 0 .566.566 0 0 0 0-.81Z"
          clipRule="evenodd"
        />
      </svg>
    );
  },
);
