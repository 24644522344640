import React from "react";
import {
  ObserverCreationTypeChoiceContent,
  ObserverHeaderCreation,
  ObserverStepCreationHeader,
} from "components";
import styles from "./ObserverCreationPage.module.css";

export const ObserverCreationPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <ObserverHeaderCreation name={"Create new observer"} />
      <ObserverStepCreationHeader
        title="Observer type"
        line1="Choose the type of observation test to be scheduled."
        line2="Each observer Type monitors different aspects of the data source, including volume, freshness, quality, and schema integrity."
      />
      <ObserverCreationTypeChoiceContent />
    </div>
  );
};
