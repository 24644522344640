import * as yup from "yup";
import { ObserverInfoFormData } from "./type";

export const schema = yup.object<ObserverInfoFormData>().shape({
  name: yup.string().required("Name is required"),
  tableName: yup.string().required("Table Name is required"),
  description: yup.string().optional(),
  resourceName: yup.string().required("Resource Name is required"),
  state: yup.string().required(),
});
