import { format, parseISO } from "date-fns";

export const formatDate = (date?: string): string => {
  if (!date) {
    return " -";
  }

  const parsedDate = parseISO(date);

  return `${format(parsedDate, "dd MMM HH:mm")}`;
};
