import React from "react";
import {
  MenuItem as MenuItemPrimitive,
  MenuItemProps as MenuItemPrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuItemProps extends MenuItemPrimitiveProps {
  children?: React.ReactNode;
}

export const MenuItem = (props: MenuItemProps) => {
  return <MenuItemPrimitive {...props}>{props.children}</MenuItemPrimitive>;
};
