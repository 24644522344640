import React from "react";
import { ObserverCompletenessCreationContainer } from "containers";

export const ObserverCompletenessCreationPage = () => {
  return (
    <div>
      <ObserverCompletenessCreationContainer />
    </div>
  );
};
