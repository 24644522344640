import { useQuery, useQueryClient } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { ObserverRepresentation } from "../../types";

export const useGetObserver = (observerId: string) => {
  const queryClient = useQueryClient();
  return useQuery<ObserverRepresentation>({
    queryKey: [QUERY_KEYS.observer, observerId],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObserver(observerId));
    },
  });
};
