import {
  AlertDetectionPeriod,
  ObserverType,
  StateType,
  TimeScheduler,
} from "./observers";

export enum QualityType {
  COMPLETENESS = "COMPLETENESS",
  COMPLIANCE = "COMPLIANCE",
  UNIQUENESS = "UNIQUENESS",
  PATTERNMATCH = "PATTERNMATCH",
}

export type QualityObserverCommand = {
  name: string;
  type: ObserverType;
  tableName: string;
  description: string;
  alertDetectionPeriod: AlertDetectionPeriod;
  startingTimeScheduler: TimeScheduler;
  resourceName: string;
  state: StateType;
  errorIf: number;
  warnIf: number;
  columnName: string;
  qualityType: QualityType;
};

export type PattenMatchObserverCommand = {
  name: string;
  type: ObserverType;
  tableName: string;
  description: string;
  alertDetectionPeriod: AlertDetectionPeriod;
  startingTimeScheduler: TimeScheduler;
  resourceName: string;
  state: StateType;
  errorIf: number;
  warnIf: number;
  columnName: string;
  qualityType: QualityType;
  regex: string;
};
export type ComplianceObserverCommand = {
  name: string;
  type: ObserverType;
  tableName: string;
  description: string;
  alertDetectionPeriod: AlertDetectionPeriod;
  startingTimeScheduler: TimeScheduler;
  resourceName: string;
  state: StateType;
  errorIf: number;
  warnIf: number;
  columnName: string;
  qualityType: QualityType;
  values: string[];
};
