import React from "react";
import { ResourceCreationContainer } from "../../containers";

export const ResourceCreationPage = () => {
  return (
    <div>
      <ResourceCreationContainer />
    </div>
  );
};
