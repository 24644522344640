import React from "react";
import {
  ObserverDetailsHeaderContainer,
  ObserverDetailsTabsSwitchContainer,
} from "containers";

export const ObserverDetailPage = () => {
  return (
    <div>
      <ObserverDetailsHeaderContainer />
      <ObserverDetailsTabsSwitchContainer />
    </div>
  );
};
