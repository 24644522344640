import React, { useEffect } from "react";
import { useAuth } from "../auth";
import { LoadingPage } from "../pages/LoadingPage";

interface PrivateRouteProps {
  component?: React.ReactElement;
}

export const PrivateRoute = ({ component }: PrivateRouteProps) => {
  const { isAuthenticated, signinRedirect, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void signinRedirect({
        redirect_uri: window.location.href,
      });
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  if (isLoading) return <LoadingPage />;

  return <>{component}</>;
};
