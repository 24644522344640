import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, Heading } from "../../_ui";
import styles from "./ObserverHeaderCreation.module.css";

interface ObserverHeaderCreationProps {
  name?: string;
}

export const ObserverHeaderCreation: React.FC<ObserverHeaderCreationProps> = ({
  name,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.headingWrapper}>
      <div className={styles.icon} onClick={handleBack}>
        <ChevronLeftIcon color={"#000000"} strokeWidth={2} />
      </div>
      <Heading level={2}>{name}</Heading>
    </div>
  );
};
