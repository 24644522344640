export const OrderedComplianceObserverCreationSteps: OrderedStep[] = [
  {
    step: 1,
    key: "OBSERVER_DATASOURCE_AND_INFO",
    label: "info & datasource",
  },
  {
    step: 2,
    key: "OBSERVER_RULE",
    label: "rules",
  },
  {
    step: 3,
    key: "SCHEDULING_AND_ALERTS",
    label: "scheduling & alert",
  },
];

export interface OrderedStep {
  step: number;
  key: string;
  label: string;
}

import { AlertUnit, QualityType, StateType, TimeUnit } from "types";

export type AlertDetectionPeriod = {
  value: number;
  unit: AlertUnit | null;
};

export type TimeScheduler = {
  date: string;
  value: number;
  unit: TimeUnit | null;
};

export type ComplianceObserverCreationFormData = {
  name: string;
  tableName: string;
  description: string;
  alertDetectionPeriod: AlertDetectionPeriod;
  startingTimeScheduler: TimeScheduler;
  resourceName: string;
  state: StateType;
  errorIf: number;
  warnIf: number;
  columnName: string;
  qualityType: QualityType;
  values: string[];
};
