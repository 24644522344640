import { Criteria } from "./api";
import { ObserverType } from "./observers";

export enum ResourceType {
  POSTGRESQL = "POSTGRESQL",
  MONGODB = "MONGODB",
}

export type ResourceDto = {
  id: string;
  host: string;
  port: number;
  username: string;
  password: string;
  dbName: string;
  schema: string;
  type: string;
  name: string;
  timestamp: string;
};

export type ResourceCommandDto = {
  host: string;
  port: number;
  username: string;
  password: string;
  dbName: string;
  schema: string;
  type: ResourceType;
  name: string;
};

export interface ResourceCriteria extends Criteria {
  name?: string;
  type?: ObserverType;
}

export interface ResourceFilters {
  searchQuery: string;
  searchType: string;
}
