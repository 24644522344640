import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetVolumeObserverStats } from "services";
import {
  ObserverDetailsOverviewSection,
  ObserverRunDetailsCard,
  ObserverVolumeGraph,
} from "components";
import styles from "./VolumeObserverDetailsContainer.module.css";
import { VolumeStatsCord } from "../../types";
import { Card, CardTitle, DateRangePicker } from "../../_ui";
import { ObserverDetailsSideCardContainer } from "../ObserverDetailsSideCardContainer";

interface VolumeObserverDetailsContainerProps {
  setShowRuns?: () => void;
}

export const VolumeObserverDetailsContainer = ({
  setShowRuns,
}: VolumeObserverDetailsContainerProps) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedRunDetails, setSelectedRunDetails] =
    useState<VolumeStatsCord | null>(null);

  const handlePointClick = (details: VolumeStatsCord) => {
    setSelectedRunDetails(details);
  };

  const handleDateChange = (newStartDate: Date, newEndDate: Date) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const { observerId } = useParams();
  const {
    data: observerLogsStats,
    isLoading,
    isFetching,
  } = useGetVolumeObserverStats({
    params: {
      id: observerId as string,
      endDate: endDate.toDateString(),
      startDate: startDate.toDateString(),
    },
  });

  useEffect(() => {
    if (
      observerLogsStats &&
      observerLogsStats.volumeObserverStatsCord.length > 0
    ) {
      setSelectedRunDetails(
        observerLogsStats?.volumeObserverStatsCord[
          observerLogsStats.volumeObserverStatsCord.length - 1
        ],
      );
    } else if (
      observerLogsStats &&
      observerLogsStats.volumeObserverStatsCord.length === 0
    ) {
      setSelectedRunDetails(null);
    }
  }, [observerLogsStats]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Card className={styles.card}>
            <CardTitle className={styles.title}>
              <div></div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </CardTitle>
            <ObserverDetailsOverviewSection
              totalRuns={observerLogsStats?.totalRuns || 0}
              successRate={observerLogsStats?.successRate || 0}
              failureRate={observerLogsStats?.failureRate || 0}
              averageRunTime={observerLogsStats?.averageRunTime || 0}
            />
            <ObserverVolumeGraph
              observerLogsStats={observerLogsStats}
              isFetching={isFetching}
              isLoading={isFetching}
              onPointClick={handlePointClick}
            />
            <ObserverRunDetailsCard runDetails={selectedRunDetails} />
          </Card>
          <ObserverDetailsSideCardContainer
            id={observerId}
            setShowRuns={setShowRuns}
          />
        </div>
      </div>
    </div>
  );
};
