import React from "react";
import {
  AppShellSidebar as AppShellSidebarPrimitive,
  AppShellSidebarProps as AppShellSidebarPrimitiveProps,
} from "@nemo-ui/design-system.ui.app-shell";

interface AppShellSidebarProps extends AppShellSidebarPrimitiveProps {
  className?: string;
}

export const AppShellSidebar = (props: AppShellSidebarProps) => {
  return <AppShellSidebarPrimitive {...props} />;
};
