import React from "react";
import { Resolver, useForm } from "react-hook-form";
import {
  Button,
  ButtonLabel,
  Form,
  FormFieldInput,
  FormFieldSelect,
  FormFieldTextArea,
} from "../../../_ui";
import styles from "./ObserverInfoForm.module.css";
import { ObserverInfoFormData } from "./type";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./utils";
import { useGetResources } from "../../../services";

interface Props {
  onSubmit: (data: ObserverInfoFormData) => void;
  formData?: ObserverInfoFormData;
}

export const ObserverInfoForm: React.FC<Props> = ({ onSubmit, formData }) => {
  // Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ObserverInfoFormData>({
    resolver: yupResolver(schema) as Resolver<ObserverInfoFormData>,
    defaultValues: formData,
  });

  //Event Handlers
  const handleOnSubmit = (data: ObserverInfoFormData) => {
    onSubmit(data);
  };

  const { data: resources = [] } = useGetResources();
  const resourceOptions = resources.map((resource) => ({
    value: resource.name,
    label: resource.name,
  }));

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"name"}
            label={"Name"}
            errorMessage={errors.name?.message}
          />
        </div>

        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"tableName"}
            label={"Table Name"}
            errorMessage={errors.tableName?.message}
          />
        </div>

        <div className={styles.inputContainer}>
          <FormFieldSelect
            variant={"outlined"}
            options={resourceOptions}
            label={"Connexion Name"}
            control={control}
            name={"resourceName"}
            placeholder={"Connexion Name"}
            errorMessage={errors.resourceName?.message}
          />
        </div>

        <div className={styles.inputContainer}>
          <FormFieldTextArea
            control={control}
            name={"description"}
            label={"Description"}
            errorMessage={errors.description?.message}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Form>
  );
};
