import React from "react";
import { ObserverSchemaCreationContainer } from "containers";

export const ObserverSchemaCreationPage = () => {
  return (
    <div>
      <ObserverSchemaCreationContainer />
    </div>
  );
};
