import React, { useEffect, useState } from "react";
import {
  ObserverFilters,
  ObserverType,
  ResourceCriteria,
  ResourceFilters,
} from "../../types";
import { API_RESOURCE_PAGE_SIZE_DEFAULT } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { parsePageNumber } from "../../utils";
import { useDeleteResource, useGetResourcePage } from "../../services";
import { ResourceDataTable, ResourceSearchSection } from "../../components";
import styles from "./ResourceListingContainer.module.css";

const RESOURCE_SEARCH_CRITERIA_DEFAULT: ResourceCriteria = {
  page: 0,
  size: API_RESOURCE_PAGE_SIZE_DEFAULT,
  sortBy: "timestamp",
  sortDirection: "DESC",
};
export const ResourceListingContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<ResourceFilters>({
    searchQuery: "",
    searchType: "",
  });

  const currentPage = parsePageNumber(searchParams.get("page"));

  const {
    data: resources,
    isLoading,
    isFetching,
  } = useGetResourcePage({
    params: {
      ...RESOURCE_SEARCH_CRITERIA_DEFAULT,
      name: filters.searchQuery,
      type: filters.searchType as ObserverType,
      page: currentPage,
    },
  });

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  const handleUpdate = (resourceId: string) => {};

  const { mutate } = useDeleteResource();
  const handleDelete = (resourceId: string) => {
    try {
      mutate(resourceId);
      console.log("Resource deleted successfully:", resourceId);
    } catch (error) {
      console.error("Error deleting resource:", error);
    }
  };

  const handleChangeFilters = (newFilters: Partial<ObserverFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const handleOnReset = () => {
    setFilters({ searchQuery: "", searchType: "" });
  };

  useEffect(() => {
    setSearchParams({
      page: (currentPage + 1).toString(),
      searchQuery: filters.searchQuery,
      searchType: filters.searchType,
    });
  }, [filters, currentPage, setSearchParams]);

  return (
    <div className={styles.wrapper}>
      <ResourceSearchSection
        filters={filters}
        onChange={handleChangeFilters}
        handleReset={handleOnReset}
      />
      <ResourceDataTable
        resources={resources && resources.content}
        isFetching={isFetching}
        isLoading={isLoading}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        currentPage={currentPage}
        totalPages={resources && resources.totalPages}
        totalElements={resources && resources.totalElements}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
};
