import React from "react";
import styles from "./Alert.module.css";

interface AlertProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}

export const Alert: React.FC<AlertProps> = ({ message, type, onClose }) => {
  return (
    <div
      className={`${styles.alert} ${type === "success" ? styles.success : styles.error}`}
    >
      <span className={styles.closeButton} onClick={onClose}>
        &times;
      </span>
      <p>{message}</p>
    </div>
  );
};
