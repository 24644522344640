import * as yup from "yup";
import { ComplianceObserverRuleFormData } from "./types";

export const schema = yup.object<ComplianceObserverRuleFormData>().shape({
  columnName: yup.string().required("ColumnName is required"),
  errorIf: yup.number().optional(),
  warnIf: yup.number().optional(),
  values: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required("Value is required"),
      }),
    )
    .required("At least one value is required"),
});
