import React, { useState } from "react";
import styles from "./ResourceCreationContainer.module.css";
import { useCreateResource } from "services";
import { ResourceCreationFormData } from "./types";
import { ObserverHeaderCreation, ResourceCreationForm } from "../../components";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../_ui";

export const ResourceCreationContainer: React.FC = () => {
  const { mutate } = useCreateResource();

  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const onSubmit: SubmitHandler<ResourceCreationFormData> = async (data) => {
    const formDataWithObserverType = {
      ...data,
    };

    try {
      mutate(formDataWithObserverType, {
        onSuccess: (response) => {
          setAlertType("success");
          setAlertMessage("Observer creation successful!");
          setShowAlert(true);
          setTimeout(() => {
            navigate("/resources");
          }, 3000);
        },
        onError: (error) => {
          console.error("Error:", error);
          setAlertType("error");
          setAlertMessage("Error occurred during observer creation.");
          setShowAlert(true);
        },
      });
    } catch (error) {
      console.error("Submit error:", error);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className={styles.wrapper}>
      {showAlert && (
        <Alert
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
        />
      )}
      <div className={styles.heading_wrapper}>
        <ObserverHeaderCreation name={"Create new Database Connection"} />
      </div>
      <ResourceCreationForm onSubmit={onSubmit} />
    </div>
  );
};
