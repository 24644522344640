import React from "react";
import {
  MenuItemLink as MenuItemLinkPrimitive,
  MenuItemProps as MenuItemPrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuItemLinkProps extends MenuItemPrimitiveProps {
  children?: React.ReactNode;
  className?: string;
  active?: boolean;
}

export const MenuItemLink = (props: MenuItemLinkProps) => {
  return (
    <MenuItemLinkPrimitive {...props}>{props.children}</MenuItemLinkPrimitive>
  );
};
