import React, { useEffect, useState } from "react";
import {
  ObserverCriteria,
  ObserverFilters,
  ObserverType,
  StateType,
} from "types";
import { API_PAGE_SIZE_DEFAULT } from "constants/api";
import { useSearchParams } from "react-router-dom";
import { useDeleteObserver, useGetObservers } from "services";
import { parsePageNumber } from "utils";
import {
  ObserversDataTable,
  ObserverSearchSection,
  ObserversOverviewSection,
} from "components";

const OBSERVER_SEARCH_CRITERIA_DEFAULT: ObserverCriteria = {
  page: 0,
  size: API_PAGE_SIZE_DEFAULT,
  sortBy: "id",
  sortDirection: "DESC",
};

export const ObserversListingContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<ObserverFilters>({
    searchQuery: "",
    searchType: "",
    searchTableName: "",
    searchStateType: "",
  });

  const currentPage = parsePageNumber(searchParams.get("page"));

  const {
    data: observers,
    isLoading,
    isFetching,
  } = useGetObservers({
    params: {
      ...OBSERVER_SEARCH_CRITERIA_DEFAULT,
      name: filters.searchQuery,
      type: filters.searchType as ObserverType,
      tableName: filters.searchTableName,
      state: filters.searchStateType as StateType,
      page: currentPage,
    },
  });

  const handleClickObserverRow = (observerId: string, type: ObserverType) => {
    const basePath = `observers/${type.toLowerCase()}/${observerId}`;
    if (
      type === ObserverType.FRESHNESS ||
      type === ObserverType.SCHEMA ||
      type === ObserverType.DATAQUALITY
    ) {
      window.location.href = `observers/${observerId}`;
    } else {
      window.location.href = basePath;
    }
  };

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  const handleUpdate = (observerId: string) => {
    window.location.href = "observers/update/" + observerId;
  };

  const { mutate } = useDeleteObserver();
  const handleDelete = (observerId: string) => {
    try {
      mutate(observerId);
      console.log("Observer deleted successfully:", observerId);
    } catch (error) {
      console.error("Error deleting observer:", error);
    }
  };

  const handleChangeFilters = (newFilters: Partial<ObserverFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const handleOnReset = () => {
    setFilters({
      searchQuery: "",
      searchType: "",
      searchTableName: "",
      searchStateType: "",
    });
  };

  useEffect(() => {
    setSearchParams({
      page: (currentPage + 1).toString(),
      searchQuery: filters.searchQuery,
      searchType: filters.searchType,
      searchTableName: filters.searchTableName,
      searchStateType: filters.searchStateType,
    });
  }, [filters, currentPage, setSearchParams]);

  return (
    <div>
      <ObserversOverviewSection />
      <ObserverSearchSection
        filters={filters}
        onChange={handleChangeFilters}
        typeSpecification={StateType}
        typeOfSource="table"
        handleReset={handleOnReset}
      />
      <ObserversDataTable
        observers={observers && observers.content}
        isFetching={isFetching}
        isLoading={isLoading}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        currentPage={currentPage}
        totalPages={observers && observers.totalPages}
        totalElements={observers && observers.totalElements}
        onPageChange={handleOnPageChange}
        onObserverRowClick={handleClickObserverRow}
      />
    </div>
  );
};
