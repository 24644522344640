import React from "react";
import { useLocation } from "react-router-dom";
import { ObserverSchedulerFormData } from "./types";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./utils";
import { AlertUnit, TimeUnit } from "../../../types";
import {
  Button,
  ButtonLabel,
  Form,
  FormFieldInput,
  FormFieldSelect,
} from "../../../_ui";
import styles from "./ObserverSchedulerForm.module.css";

interface Props {
  onSubmit: (data: ObserverSchedulerFormData) => void;
  onClickBackButton: () => void;
}

export const ObserverSchedulerForm: React.FC<Props> = ({
  onSubmit,
  onClickBackButton,
}) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const type = pathSegments[pathSegments.length - 1];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ObserverSchedulerFormData>({
    resolver: yupResolver(schema) as Resolver<ObserverSchedulerFormData>,
    defaultValues: {
      alertDetectionPeriod: { unit: AlertUnit.DAYS, value: 1 },
      startingTimeScheduler: { value: 0, unit: null, date: "" },
    },
  });

  const handleOnSubmit = (data: ObserverSchedulerFormData) => {
    onSubmit(data);
  };

  const handleBack = () => {
    onClickBackButton();
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.container}>
        {(type === "freshness" || type === "volume") && (
          <>
            <div className={styles.inputContainer}>
              <FormFieldInput
                type={"number"}
                control={control}
                name={"alertDetectionPeriod.value"}
                label={"Alert Detection Period"}
                errorMessage={errors.alertDetectionPeriod?.value?.message}
              />
            </div>

            <div className={styles.inputContainer}>
              <FormFieldSelect
                variant={"outlined"}
                placeholder={"Enter unit"}
                control={control}
                name={"alertDetectionPeriod.unit"}
                label={"Alert Detection Period Unit"}
                options={Object.values(AlertUnit).map((unit) => ({
                  value: unit,
                  label: unit,
                }))}
                errorMessage={errors.alertDetectionPeriod?.unit?.message}
              />
            </div>
          </>
        )}

        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"startingTimeScheduler.date"}
            label={"Starting Date"}
            type="datetime-local"
            errorMessage={errors.startingTimeScheduler?.date?.message}
          />
        </div>

        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"startingTimeScheduler.value"}
            label={"Run every"}
            type={"number"}
            errorMessage={errors.startingTimeScheduler?.value?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldSelect
            placeholder={"Enter unit"}
            variant={"outlined"}
            control={control}
            name={"startingTimeScheduler.unit"}
            label={"Unit"}
            options={Object.values(TimeUnit).map((unit) => ({
              value: unit,
              label: unit,
            }))}
            errorMessage={errors.startingTimeScheduler?.unit?.message}
          />
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.buttonGost} onClick={handleBack}>
            <ButtonLabel>Back</ButtonLabel>
          </button>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Form>
  );
};
