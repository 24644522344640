import React from "react";
import { ResourceDto } from "types";
import styles from "./ResourceDateTable.module.css";
import {
  Card,
  Empty,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_ui";
import { ResourceDataTableRow } from "components/ResourceDataTableRow";

interface Props {
  resources?: ResourceDto[];
  handleUpdate: (observerId: string) => void;
  handleDelete: (observerId: string) => void;
  isFetching: boolean;
  isLoading: boolean;
  currentPage?: number;
  totalPages?: number;
  totalElements?: number;
  onPageChange: (page: number) => void;
}

const headerCells = [
  "Type",
  "Name",
  "Created at",
  "Host & Port",
  "Database",
  "Schema",
  "Actions",
];
export const ResourceDataTable: React.FC<Props> = ({
  resources = [],
  handleUpdate,
  handleDelete,
  isLoading,
  isFetching,
  currentPage = 0,
  totalPages = 1,
  totalElements,
  onPageChange,
}) => {
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };
  const showPagination = totalPages > 1;

  if (isLoading) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <div>
            <Text size={"lg"}>Loading Resources...</Text>
          </div>
        </Card>
      </div>
    );
  }
  if (!isLoading && resources && resources.length === 0) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <Empty message={"No resources found."} />
        </Card>
      </div>
    );
  }
  return (
    <div className={styles.tableContainer}>
      <Table>
        <TableHeader>
          {headerCells.map((cell) => (
            <TableHead key={cell}>{cell}</TableHead>
          ))}
        </TableHeader>
        <TableBody>
          {isFetching && <ProgressBar position={"absolute"} />}
          {resources.map((resource) => (
            <ResourceDataTableRow
              resource={resource}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              key={resource.id}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableFooterCell colSpan={headerCells.length}>
            <div className={styles.footer}>
              <Text>{totalElements} resources</Text>
              {showPagination && (
                <Pagination
                  totalPages={totalPages}
                  onPageChange={handleOnPageChange}
                  currentPage={currentPage}
                />
              )}
            </div>
          </TableFooterCell>
        </TableFooter>
      </Table>
    </div>
  );
};
