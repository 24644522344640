import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const SchemaIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          d="M9.27819 13.83H10.7539C11.5689 13.83 12.2296 13.1693 12.2296 12.3543V10.5551C12.2296 9.87789 12.6905 9.28768 13.3474 9.12343L14.4652 8.84393C14.6515 8.79737 14.6515 8.53263 14.4652 8.48607L13.3474 8.20657C12.6905 8.04232 12.2296 7.45211 12.2296 6.77494V3.5H9.27819M6.32676 3.5H4.85104C4.03603 3.5 3.37533 4.1607 3.37533 4.97571V6.77494C3.37533 7.45211 2.91447 8.04232 2.25753 8.20657L1.13973 8.48607C0.953425 8.53263 0.953425 8.79737 1.13973 8.84393L2.25753 9.12343C2.91447 9.28768 3.37533 9.87789 3.37533 10.5551V13.83H6.32676"
          stroke={color}
        />
      </svg>
    );
  },
);
