import React from "react";
import styles from "./ObserverDetailHeader.module.css";
import { ChevronLeftIcon } from "../../_ui";
import { StateType } from "../../types";
import { useNavigate } from "react-router-dom";
import { observerIcons, observerState } from "utils";

interface ObserverDetailHeaderProps {
  type?: string;
  state?: StateType;
  source?: string;
  name?: string;
}

const capitalizeFirstLetter = (string?: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const ObserverDetailHeader = ({
  type,
  state,
  source,
  name,
}: ObserverDetailHeaderProps) => {
  const navigate = useNavigate();
  const handleTurnBack = () => {
    navigate("/observers");
  };

  return (
    <div>
      <div className={styles.title}>
        <div className={styles.icon} onClick={handleTurnBack}>
          <ChevronLeftIcon color={"#000000"} strokeWidth={2} />
        </div>
        {capitalizeFirstLetter(type?.toLowerCase())} Observer
      </div>
      <div className={styles.details}>
        <div className={styles.column}>
          <div className={styles.titleColum}>Observer name</div>
          <div className={styles.name}>{name}</div>
        </div>
        <div className={styles.column}>
          <div className={styles.titleColum}>Observer type</div>
          <div>
            {observerIcons[type || ""] &&
              React.cloneElement(observerIcons[type || ""])}
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.titleColum}>Observer state</div>
          <div>
            {observerState[state || ""] &&
              React.cloneElement(observerState[state || ""])}
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.titleColum}>Observer source</div>
          <div className={styles.source}>{source}</div>
        </div>
      </div>
    </div>
  );
};
