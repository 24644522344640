import * as yup from "yup";
import { ObserverSchedulerFormData } from "./types";

export const schema = yup.object<ObserverSchedulerFormData>().shape({
  alertDetectionPeriod: yup.object().shape({
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === null || originalValue === "") {
          return undefined;
        }
        return value;
      })
      .required("Alert Detection Period value is required")
      .min(0, "Alert Detection Period value must be a positive number"),
    unit: yup.string().required("Alert Detection Period Unit is required"),
  }),
  startingTimeScheduler: yup.object().shape({
    date: yup.string().required("Starting Date is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === null || originalValue === "") {
          return undefined;
        }
        return value;
      })
      .required("Starting Time value is required")
      .min(0, "Starting Time value must be a positive number"),
    unit: yup.string().required("Starting Time Unit is required"),
  }),
});
