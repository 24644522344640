import { useQuery } from "react-query";
import { ClientApi } from "services";
import { API_ENDPOINTS, QUERY_KEYS } from "config";

export const useGetObserverTableNames = () => {
  return useQuery<string[]>({
    queryKey: [QUERY_KEYS.tableNames],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObserverTableNames());
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
