import React from "react";
import {
  Card as CardPrimitive,
  CardProps as CardPropsPrimitive,
} from "@nemo-ui/design-system.ui.card";

interface CardProps extends CardPropsPrimitive {
  children?: React.ReactNode;
}

export const Card = (props: CardProps) => {
  return <CardPrimitive {...props}>{props.children}</CardPrimitive>;
};
