import React from "react";
import {
  CardBody as CardBodyPrimitive,
  CardBodyProps as CardBodyPropsPrimitive,
} from "@nemo-ui/design-system.ui.card";

interface CardBodyProps extends CardBodyPropsPrimitive {
  children?: React.ReactNode;
}

export const CardBody = (props: CardBodyProps) => {
  return <CardBodyPrimitive {...props}>{props.children}</CardBodyPrimitive>;
};
