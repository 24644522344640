import React from "react";
import {
  MenuTitle as MenuTitlePrimitive,
  MenuTitleProps as MenuTitlePrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuTitleProps extends MenuTitlePrimitiveProps {
  children?: React.ReactNode;
}

export const MenuTitle = (props: MenuTitleProps) => {
  return <MenuTitlePrimitive {...props}>{props.children}</MenuTitlePrimitive>;
};
