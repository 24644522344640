import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const ConnexionsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        height="16px"
        width="16px"
        version="1.1"
        id="Capa_1"
        viewBox="0 0 490 490"
        ref={ref}
        {...props}
      >
        <title>{title}</title>
        <polygon points="107.188,404.465 404.465,107.188 490,107.188 490,0 382.813,0 382.813,85.535 85.535,382.813 0,382.813 0,490 107.188,490" />
      </svg>
    );
  },
);
