import React, { useCallback, useEffect, useRef, useState } from "react";
import { ObserverStats } from "types";
import { Text } from "_ui";
import styles from "./ObserverGraph.module.css";
import Chart, { ActiveElement, ChartEvent } from "chart.js/auto";
import { formatDate } from "utils";

interface ObserverGraphProps {
  observerLogsStats: ObserverStats[] | undefined;
  isFetching: boolean;
  isLoading: boolean;
  onBarClick: (runDetails: ObserverStats) => void;
}

export const ObserverGraph: React.FC<ObserverGraphProps> = ({
  observerLogsStats,
  isLoading,
  onBarClick,
}) => {
  const graphRef = useRef<HTMLCanvasElement>(null);
  const [initialized, setInitialized] = useState(false);

  const handleBarClick = useCallback(
    (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        onBarClick(observerLogsStats![index]);
      }
    },
    [onBarClick, observerLogsStats],
  );

  useEffect(() => {
    if (observerLogsStats && observerLogsStats.length > 0 && graphRef.current) {
      const ctx = graphRef.current.getContext("2d");
      if (ctx) {
        const sortedStats = observerLogsStats.sort(
          (a, b) =>
            new Date(a.executionDate).getTime() -
            new Date(b.executionDate).getTime(),
        );

        const labels = sortedStats.map((stat) =>
          formatDate(stat.executionDate),
        );
        const passedData = sortedStats.map((stat) =>
          stat.status === "PASSED" ? 1 : 0,
        );
        const failedData = sortedStats.map((stat) =>
          stat.status === "FAILED" ? 1 : 0,
        );
        const warningRunningData = sortedStats.map((stat) =>
          stat.status === "WARNING" ? 1 : 0,
        );
        const runningData = sortedStats.map((stat) =>
          stat.status === "RUNNING" ? 1 : 0,
        );

        const chart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Passed",
                data: passedData,
                backgroundColor: "#41B06E",
                barThickness: 5,
              },
              {
                label: "Failed",
                data: failedData,
                backgroundColor: "#FB2942",
                barThickness: 5,
              },
              {
                label: "Warning",
                data: warningRunningData,
                backgroundColor: "#EFDD3B",
                barThickness: 5,
              },
              {
                label: "Running",
                data: runningData,
                backgroundColor: "#FF9800",
                barThickness: 5,
              },
            ],
          },
          options: {
            indexAxis: "x",
            scales: {
              x: {
                ticks: {
                  stepSize: 1,
                },
              },
              y: {
                ticks: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const stat = sortedStats[context.dataIndex];
                    return `${stat.status} on ${formatDate(stat.executionDate)}`;
                  },
                },
              },
            },
            animation: initialized ? { duration: 0 } : { duration: 2000 },
            onClick: handleBarClick,
          },
        });

        setInitialized(true);

        return () => {
          chart.destroy();
        };
      }
    }
  }, [observerLogsStats, handleBarClick, initialized]);

  if (isLoading) {
    return (
      <div>
        <Text size="lg">{"Loading observer chart..."}</Text>
      </div>
    );
  }

  return (
    <div className={styles.graphContainer}>
      <div className={styles.card}>
        {!isLoading && observerLogsStats && observerLogsStats.length === 0 && (
          <div>
            <Text size="lg">No runs in this period.</Text>
          </div>
        )}
        <div className={styles.body}>
          <canvas ref={graphRef} height={80}></canvas>
        </div>
      </div>
    </div>
  );
};
