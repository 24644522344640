import React from "react";
import {
  TableCell as TableCellPrimitive,
  TableCellProps as TableCellPrimitiveProps,
} from "@nemo-ui/design-system.ui.table";

interface TableCellProps extends TableCellPrimitiveProps {}

export const TableCell = (props: TableCellProps) => {
  return <TableCellPrimitive {...props}>{props.children}</TableCellPrimitive>;
};
