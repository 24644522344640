import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { ResourceCommandDto } from "../../types";

export const useCreateResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createResource],
    mutationFn: (resourceCreationDto: ResourceCommandDto) => {
      return ClientApi.post(
        API_ENDPOINTS.createResource(),
        resourceCreationDto,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.resources], {
        exact: false,
      });
    },
  });
};
