import React from "react";
import { Card } from "../../_ui";
import styles from "./ObserverDetailsSideCard.module.css";
import { ObserverNextRunDelay, ObserverPreviousRuns } from "components";
import { ObserverLogsPageDto } from "../../types";

interface ObserverDetailsSideCardProps {
  observerLog?: ObserverLogsPageDto[];
  isLoading?: boolean;
  isFetching?: boolean;
  setShowRuns?: (() => void) | undefined;
}

export const ObserverDetailsSideCard = ({
  observerLog,
  isLoading,
  isFetching,
  setShowRuns,
}: ObserverDetailsSideCardProps) => {
  const nextRunDate =
    observerLog && observerLog.length > 0 ? observerLog[0].nextRun : null;

  return (
    <div>
      {isLoading || isFetching ? (
        <div>Loading...</div>
      ) : observerLog && observerLog.length > 0 ? (
        <>
          <Card className={styles.card}>
            <div className={styles.title}>Next run on</div>
            <ObserverNextRunDelay date={nextRunDate} />
            <div className={styles.title}>Previous runs</div>
            <ObserverPreviousRuns
              runs={observerLog}
              setShowRuns={setShowRuns}
            />
          </Card>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
