import { ConnexionsIcon } from "_ui";
import { IoEyeSharp } from "react-icons/io5";

export const MENU = [
  {
    title: "",
    items: [
      {
        title: "connexions",
        icon: ConnexionsIcon,
        path: "/resources",
      },
      {
        title: "Observers",
        icon: IoEyeSharp,
        path: "/observers",
      },
    ],
  },
] as const;
