import React from "react";
import styles from "./ObserverDetailsOverviewSection.module.css";
import { ObserverDetailsOverviewCard } from "../ObserverDetailsOverviewCard";

interface ObserverDetailsOverviewSectionProps {
  totalRuns: number;
  successRate: number;
  failureRate: number;
  averageRunTime: number;
}

export const ObserverDetailsOverviewSection = ({
  totalRuns,
  successRate,
  failureRate,
  averageRunTime,
}: ObserverDetailsOverviewSectionProps) => {
  return (
    <div className={styles.container}>
      <ObserverDetailsOverviewCard
        name={"Runs number"}
        value={totalRuns}
        description={"Executed runs"}
        unit={""}
      />
      <ObserverDetailsOverviewCard
        name={"Success rate"}
        value={successRate}
        description={"Succeed rate"}
        unit={"%"}
      />
      <ObserverDetailsOverviewCard
        name={"Failure rate"}
        value={failureRate}
        description={"Failed run"}
        unit={"%"}
      />
      <ObserverDetailsOverviewCard
        name={"Average run time"}
        value={averageRunTime}
        description={"Per run"}
        unit={"seconds"}
      />
    </div>
  );
};
