import { forwardRef } from "react";
import { IconProps } from "./types";

export const ChevronRightIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", strokeWidth = 0, title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          fillRule="evenodd"
          stroke={color}
          strokeWidth={strokeWidth}
          d="M13.94 12 8.47 6.53l1.061-1.06 6.53 6.53-6.53 6.53-1.06-1.06L13.94 12Z"
          clipRule="evenodd"
        />
      </svg>
    );
  },
);
