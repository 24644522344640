import React from "react";
import {
  Tabs as TabsPrimary,
  TabsProps as TabsPrimaryProps,
} from "@nemo-ui/design-system.ui.tabs";

interface TabsProps extends TabsPrimaryProps {
  children?: React.ReactNode;
}

export const Tabs = (props: TabsProps) => {
  return <TabsPrimary {...props}>{props.children}</TabsPrimary>;
};
