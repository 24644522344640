import React from "react";
import styles from "./ObserverDetailsSearchSection.module.css";
import { StateType, StatusType } from "types";
import { Button, Select } from "_ui";

interface Props {
  filters: {
    searchQuery: string;
    searchType: string;
    searchTableName: string;
    searchStateType: string;
  };
  onChange: (
    newFilters: Partial<{
      searchQuery: string;
      searchType: string;
      searchTableName: string;
      searchStateType: string;
    }>,
  ) => void;
  typeOfSource: string;
  typeSpecification: typeof StatusType | typeof StateType;
  handleReset: () => void;
}

export const ObserverDetailsSearchSection: React.FC<Props> = ({
  onChange,
  typeSpecification,
  handleReset,
}) => {
  const handleSearchStateTypeChange = (value: string) =>
    onChange({ searchStateType: value });

  return (
    <div className={styles.searchSection}>
      <Select
        className={styles.select}
        variant={"outlined"}
        defaultValue={""}
        onChange={handleSearchStateTypeChange}
        options={Object.values(typeSpecification).map((unit) => ({
          value: unit,
          label: unit,
        }))}
        placeholder="Status"
      />

      <Button
        variant={"neutral"}
        onClick={handleReset}
        className={styles.reset}
      >
        Reset
      </Button>
    </div>
  );
};
