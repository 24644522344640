import React from "react";
import styles from "./SearchSection.module.css";
import { ObserverType, StateType, StatusType } from "types";
import { Button, Searchbar, Select } from "_ui";
import { useGetObserverTableNames, useGetResources } from "services";

interface Props {
  filters: {
    searchQuery: string;
    searchType: string;
    searchTableName: string;
    searchStateType: string;
  };
  onChange: (
    newFilters: Partial<{
      searchQuery: string;
      searchType: string;
      searchTableName: string;
      searchStateType: string;
    }>,
  ) => void;
  typeOfSource: string;
  typeSpecification: typeof StatusType | typeof StateType;
  handleReset: () => void;
}

export const ObserverSearchSection: React.FC<Props> = ({
  filters,
  onChange,
  typeSpecification,
  typeOfSource,
  handleReset,
}) => {
  const { data: tables = [] } = useGetObserverTableNames();
  const tableOptions = tables.map((table) => ({ value: table, label: table }));
  const { data: resources = [] } = useGetResources();
  const resourceOptions = resources.map((resource) => ({
    value: resource.name,
    label: resource.name,
  }));

  const handleSearchQueryChange = (value: string) =>
    onChange({ searchQuery: value });
  const handleSearchTypeChange = (value: string) =>
    onChange({ searchType: value });
  const handleSearchTableNameChange = (value: string) =>
    onChange({ searchTableName: value });
  const handleSearchStateTypeChange = (value: string) =>
    onChange({ searchStateType: value });

  return (
    <div className={styles.searchSection}>
      <Searchbar
        value={filters.searchQuery}
        onChange={handleSearchQueryChange}
        placeholder="Search by name"
        className={styles.input}
        fullWidth={false}
      />
      <Select
        className={styles.select}
        variant={"outlined"}
        defaultValue={""}
        onChange={handleSearchTypeChange}
        options={Object.values(ObserverType).map((unit) => ({
          value: unit,
          label: unit,
        }))}
        placeholder="Type"
      />
      <Select
        className={styles.select}
        variant={"outlined"}
        defaultValue={""}
        onChange={handleSearchStateTypeChange}
        options={Object.values(typeSpecification).map((unit) => ({
          value: unit,
          label: unit,
        }))}
        placeholder="Status"
      />
      <Select
        className={styles.select}
        variant={"outlined"}
        defaultValue={""}
        onChange={handleSearchTableNameChange}
        options={typeOfSource === "table" ? tableOptions : resourceOptions}
        placeholder="Source"
      />
      <Button
        variant={"neutral"}
        onClick={handleReset}
        className={styles.reset}
      >
        Reset
      </Button>
    </div>
  );
};
