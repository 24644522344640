import * as yup from "yup";
import { VolumeObserverRuleFormData } from "./types";

export const schema = yup.object<VolumeObserverRuleFormData>().shape({
  timestamp: yup.string().required("Timestamp is required"),
  maxRowExpected: yup
    .number()
    .required("max expected row value is required")
    .min(0, "max expected row  value must be a positive number"),
  minRowExpected: yup
    .number()
    .required("min expected row value is required")
    .min(0, "min expected row  value must be a positive number"),
});
