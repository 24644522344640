import React, { useEffect, useState } from "react";
import { Card, CardTitle, DateRangePicker } from "_ui";
import { useGetObserversStats } from "services";
import { useParams } from "react-router-dom";
import {
  ObserverDetailsOverviewSection,
  ObserverGraph,
  ObserverRunDetailsCard,
} from "components";
import styles from "./ObserverDetailsContainer.module.css";
import { ObserverDetailsSideCardContainer } from "../ObserverDetailsSideCardContainer";
import { ObserverStats } from "../../types";

interface ObserverDetailsContainerProps {
  setShowRuns?: () => void;
}

export const ObserverDetailsContainer = ({
  setShowRuns,
}: ObserverDetailsContainerProps) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedRunDetails, setSelectedRunDetails] =
    useState<ObserverStats | null>(null);

  const handleBarClick = (runDetails: ObserverStats) => {
    setSelectedRunDetails(runDetails);
  };

  const handleDateChange = (newStartDate: Date, newEndDate: Date) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const { observerId } = useParams();

  const {
    data: observerLogsStats,
    isLoading,
    isFetching,
  } = useGetObserversStats({
    params: {
      id: observerId as string,
      endDate: endDate.toDateString(),
      startDate: startDate.toDateString(),
    },
  });
  useEffect(() => {
    if (observerLogsStats && observerLogsStats.observerStats.length > 0) {
      setSelectedRunDetails(
        observerLogsStats?.observerStats[
          observerLogsStats.observerStats.length - 1
        ],
      );
    } else if (
      observerLogsStats &&
      observerLogsStats.observerStats.length == 0
    ) {
      setSelectedRunDetails(null);
    }
  }, [observerLogsStats]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Card className={styles.card}>
          <CardTitle className={styles.title}>
            <div></div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={handleDateChange}
            />
          </CardTitle>
          <ObserverDetailsOverviewSection
            totalRuns={observerLogsStats?.totalRuns || 0}
            successRate={observerLogsStats?.successRate || 0}
            failureRate={observerLogsStats?.failureRate || 0}
            averageRunTime={observerLogsStats?.averageRunTime || 0}
          />
          <ObserverGraph
            observerLogsStats={observerLogsStats?.observerStats || []}
            isFetching={isFetching}
            isLoading={isLoading}
            onBarClick={handleBarClick}
          />
          <ObserverRunDetailsCard runDetails={selectedRunDetails} />
        </Card>
        <ObserverDetailsSideCardContainer
          id={observerId}
          setShowRuns={setShowRuns}
        />
      </div>
    </div>
  );
};
