import React from "react";
import {
  Heading as HeadingPrimitive,
  HeadingProps as HeadingPropsPrimitive,
} from "@nemo-ui/design-system.ui.heading";

interface HeadingProps extends HeadingPropsPrimitive {
  children?: string;
}

export const Heading = (props: HeadingProps) => {
  return <HeadingPrimitive {...props}>{props.children}</HeadingPrimitive>;
};
