import * as yup from "yup";
import { ResourceFormData } from "./types";

export const schema = yup.object<ResourceFormData>().shape({
  name: yup.string().required("Name is required"),
  username: yup.string().required("UserName is required"),
  host: yup.string().required("Host is required"),
  password: yup.string().required("Password  is required"),
  schema: yup.string().required("Schema Name is required"),
  dbName: yup.string().required("Database Name is required"),
  port: yup
    .number()
    .required("Port is required")
    .min(1, "Port value must be a positive number"),
  type: yup.string().required("Type is required"),
});
