import React from "react";
import { TableFooterCell as TableFooterCellPrimitive } from "@nemo-ui/design-system.ui.table";

interface TableFooterCell {
  colSpan: number;
  children: React.ReactNode;
}

export const TableFooterCell = (props: TableFooterCell) => {
  return (
    <TableFooterCellPrimitive {...props}>
      {props.children}
    </TableFooterCellPrimitive>
  );
};
