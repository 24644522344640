import React from "react";
import {
  Avatar as AvatarPrimitive,
  AvatarProps as AvatarPrimitiveProps,
} from "@nemo-ui/design-system.ui.avatar";

interface AvatarProps extends AvatarPrimitiveProps {}

export const Avatar = (props: AvatarProps) => {
  return <AvatarPrimitive {...props} />;
};
