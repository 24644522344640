import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const VolumeIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          d="M2.66666 4.49992V12.4999C2.66666 13.9727 5.05447 15.1666 7.99999 15.1666C10.9455 15.1666 13.3333 13.9727 13.3333 12.4999V4.49992M2.66666 4.49992C2.66666 3.02716 5.05447 1.83325 7.99999 1.83325C10.9455 1.83325 13.3333 3.02716 13.3333 4.49992M2.66666 4.49992C2.66666 5.97268 5.05447 7.16658 7.99999 7.16658C10.9455 7.16658 13.3333 5.97268 13.3333 4.49992M13.3333 8.49992C13.3333 9.97265 10.9455 11.1666 7.99999 11.1666C5.05447 11.1666 2.66666 9.97265 2.66666 8.49992"
          stroke={color}
        />
      </svg>
    );
  },
);
