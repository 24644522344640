import {
  ObserverLogsCriteria,
  ObserverLogsPageDto,
  PagedResponse,
} from "types";
import { useQuery } from "react-query";
import { ClientApi } from "services/ClientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "config";

interface UseGetObserverLogsProps {
  params: ObserverLogsCriteria;
}

export const useGetObserverLogs = ({ params }: UseGetObserverLogsProps) => {
  return useQuery<PagedResponse<ObserverLogsPageDto>>({
    queryKey: [QUERY_KEYS.observerLogs, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObserverLogs(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
