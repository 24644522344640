import React from "react";
import {
  MenuList as MenuListPrimitive,
  MenuListProps as MenuListPrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuListProps extends MenuListPrimitiveProps {
  children?: React.ReactNode;
}

export const MenuList = (props: MenuListProps) => {
  return <MenuListPrimitive {...props}>{props.children}</MenuListPrimitive>;
};
