import React from "react";
import { QupidRoutes } from "./routes";
import { AuthProvider } from "./auth";
import "@nemo-ui/design-system.themes.base-theme";
import "custumStyle.css";

function App() {
  return (
    <AuthProvider>
      <QupidRoutes />
    </AuthProvider>
  );
}

export default App;
