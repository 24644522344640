import React from "react";
import clsx from "clsx";
import styles from "./SelectOption.module.css";
import { Option } from "../types";
import { Text } from "_ui";
import { UseSelectPropGetters } from "downshift";

interface SelectOptionProps {
  selectedOption: Option | null;
  highlightedIndex: number;
  index: number;
  option: Option;
  getItemProps: UseSelectPropGetters<Option>["getItemProps"];
}

export const SelectOption = ({
  option,
  selectedOption,
  highlightedIndex,
  index,
  getItemProps,
}: SelectOptionProps) => {
  const className = clsx(
    "nemo-select-option",
    styles.wrapper,
    highlightedIndex === index && styles.highlighted_option,
    selectedOption &&
      selectedOption.value === option.value &&
      styles.selected_option,
  );

  return (
    <li
      className={className}
      key={option.value}
      {...getItemProps({ item: option, index })}
      title={option.value}
    >
      <Text>{option.label}</Text>
    </li>
  );
};
