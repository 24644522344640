import { User } from "oidc-client-ts";
import { REACT_APP_KEYCLOAK_CLIENT_ID, REACT_APP_KEYCLOAK_URL } from "config";
import { jwtDecode } from "jwt-decode";

const KEYCLOAK_TOKEN_KEY = `oidc.user:${REACT_APP_KEYCLOAK_URL}:${REACT_APP_KEYCLOAK_CLIENT_ID}`;
export const getUser = () => {
  const oidcStorage = localStorage.getItem(KEYCLOAK_TOKEN_KEY);
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
};

export const getAccessToken = () => {
  const user = getUser();

  if (!user) {
    return null;
  }

  const access_token = user.access_token;

  if (isExpired(access_token)) {
    localStorage.removeItem(KEYCLOAK_TOKEN_KEY);
    return null;
  }

  return access_token;
};

const isExpired = (jwt: string | undefined): boolean => {
  if (!jwt) {
    return true;
  }
  const decodedCode: { exp: number } = jwtDecode(jwt);
  if (!decodedCode || !decodedCode.exp) {
    return true;
  }
  return Date.now() >= decodedCode.exp * 1000;
};
