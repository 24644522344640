import React, { useEffect, useState } from "react";
import styles from "./ObserverNextRunDelay.module.css";

interface ObserverNextRunDelayProps {
  date?: string | null;
}

export const ObserverNextRunDelay = ({ date }: ObserverNextRunDelayProps) => {
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    if (date) {
      const calculateTimeDifference = () => {
        const now = new Date();
        const nextRunDate = new Date(date);
        const diff = nextRunDate.getTime() - now.getTime();

        if (diff > 0) {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const formattedTime = `${days} days, ${hours} hours, and ${minutes} minutes`;

          setTimeDifference(formattedTime);
        } else {
          setTimeDifference("The next run date has passed");
        }
      };

      calculateTimeDifference();
      const intervalId = setInterval(calculateTimeDifference, 60000);
      return () => clearInterval(intervalId);
    } else {
      setTimeDifference("No upcoming runs");
    }
  }, [date]);

  return (
    <div className={styles.container}>
      <div className={styles.phrase}>
        {timeDifference || "Calculating time difference..."}
      </div>
    </div>
  );
};
