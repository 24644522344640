import React, { useState } from "react";
import {
  AlertDialog,
  DotsHorizontalIcon,
  MongodbIcon,
  Popover,
  PostgresqlIcon,
  TableCell,
  TableRow,
} from "_ui";
import { ResourceDto } from "../../types";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import styles from "./ResourceDataTableRow.module.css";
import { formatDate } from "../../utils";

interface ResourceDataTableRowProps {
  resource: ResourceDto;
  handleUpdate: (resourceId: string) => void;
  handleDelete: (resourceId: string) => void;
}

const resourceType: { [key: string]: JSX.Element } = {
  POSTGRESQL: <PostgresqlIcon />,
  MONGODB: <MongodbIcon />,
};

export const ResourceDataTableRow = ({
  resource,
  handleUpdate,
  handleDelete,
}: ResourceDataTableRowProps) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // State to manage dialog visibility

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const confirmDelete = () => {
    handleDelete(resource.id);
    closeDeleteDialog();
  };

  return (
    <TableRow>
      <TableCell>
        {resourceType[resource.type] &&
          React.cloneElement(resourceType[resource.type])}
      </TableCell>
      <TableCell>
        <span className={styles.name}>{resource.name.toUpperCase()}</span>
      </TableCell>
      <TableCell>
        <span className={styles.name}>
          {formatDate(resource.timestamp).toLowerCase()}
        </span>
      </TableCell>
      <TableCell>
        <span className={styles.source}>
          {resource.host.toLowerCase()}:{resource.port}
        </span>
      </TableCell>
      <TableCell>
        <span className={styles.name}>{resource.dbName.toLowerCase()}</span>
      </TableCell>
      <TableCell>
        <span className={styles.name}>{resource.schema.toLowerCase()}</span>
      </TableCell>
      <TableCell align={"right"}>
        <Popover trigger={<DotsHorizontalIcon />}>
          <ul className={styles.ul}>
            <li>
              <button
                onClick={() => handleUpdate(resource.id)}
                className={styles.updateButton}
              >
                <span>Update </span>
                <FaPenToSquare />
              </button>
            </li>
            <li>
              <button
                onClick={openDeleteDialog}
                className={styles.deleteButton}
              >
                <span>Delete </span>
                <RiDeleteBin5Fill />
              </button>
            </li>
          </ul>
        </Popover>
      </TableCell>

      <AlertDialog
        isOpen={showDeleteDialog}
        message={`By deleting '${resource.name}' resource,all it's observer's and runs will be deleted.`}
        onClose={closeDeleteDialog}
        onConfirm={confirmDelete}
      />
    </TableRow>
  );
};
