import React from "react";
import styles from "./ObserverStepCreationHeader.module.css";

interface ObserverStepCreationHeaderProps {
  title?: string;
  line1?: string;
  line2?: string;
}

export const ObserverStepCreationHeader: React.FC<
  ObserverStepCreationHeaderProps
> = ({ title, line1, line2 }) => {
  return (
    <div className={styles.container}>
      {title && <p className={styles.title}>{title}</p>}
      {line1 && <p className={styles.text}>{line1}</p>}
      {line2 && <p className={styles.text}>{line2}</p>}
    </div>
  );
};
