import React from "react";
import { NavLink } from "react-router-dom";
import {
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
  MenuItemLink,
  MenuList,
  MenuTitle,
} from "_ui";
import { MENU } from "constants/menu";
import { AuthItem } from "../AuthItem";
import styles from "./AppSideMenu.module.css";

interface AppSideMenuProps {
  isSideBarOpen: boolean;
}

export const AppSideMenu = ({ isSideBarOpen }: AppSideMenuProps) => {
  return (
    <div className={styles.container}>
      {MENU.map((menu, i) => {
        return (
          <MenuList key={i}>
            {menu.title && <MenuTitle>{menu.title}</MenuTitle>}
            {menu.items.map((item, index) => {
              return (
                <MenuItem key={item.path}>
                  <NavLink
                    to={item.path}
                    style={{ width: "100%" }}
                    className={styles.navLink}
                  >
                    {({ isActive }) => (
                      <MenuItemLink active={isActive}>
                        <MenuItemIcon>
                          <item.icon />
                        </MenuItemIcon>
                        {isSideBarOpen && (
                          <MenuItemLabel>{item.title}</MenuItemLabel>
                        )}
                      </MenuItemLink>
                    )}
                  </NavLink>
                </MenuItem>
              );
            })}

            <AuthItem isSideBarOpen={isSideBarOpen} />
          </MenuList>
        );
      })}
    </div>
  );
};
