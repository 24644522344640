import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { SchemaObserverCreationDto } from "types";

export const useCreateSchemaObserver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createSchemaObserver],
    mutationFn: (schemaDto: SchemaObserverCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.createSchemaObservers(), schemaDto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.observers], {
        exact: false,
      });
    },
  });
};
