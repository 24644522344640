import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "routes";
import { ROUTE } from "./types";
import { PrivateRoute } from "./PrivateRoute";

const renderRoutes = (routes: ROUTE[], parentKey = "") => {
  return routes.map((route, index) => {
    const { path, element, children, isPrivate, crumb } = route;
    const firstChild = children && children.length > 0 && children[0];

    const Element = isPrivate ? <PrivateRoute component={element} /> : element;

    const key = parentKey ? `${parentKey}-${index}` : `${crumb}-${index}`;

    return (
      <Route key={key} path={path} element={Element}>
        {firstChild && (
          <Route
            key={`${key}-index`}
            index={true}
            element={
              firstChild.element || <Navigate to={firstChild.path || ""} />
            }
          />
        )}
        {children && renderRoutes(children, key)}
      </Route>
    );
  });
};

export const QupidRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>{renderRoutes(ROUTES)}</Routes>
    </BrowserRouter>
  );
};
