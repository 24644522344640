import React from "react";
import {
  ButtonIcon as ButtonIconPrimary,
  ButtonIconProps as ButtonIconPrimaryProps,
} from "@nemo-ui/design-system.ui.button";

interface ButtonIconProps extends ButtonIconPrimaryProps {
  children?: React.ReactNode;
}

export const ButtonIcon = (props: ButtonIconProps) => {
  return <ButtonIconPrimary {...props}>{props.children}</ButtonIconPrimary>;
};
