import React from "react";
import styles from "./Badge.module.css";

interface Props {
  icon: React.ReactNode;
  text: string;
  color?: string;
  backgroundColor?: string;
}

export const Badge = ({
  icon,
  text,
  color = "#000",
  backgroundColor = "#EBE8FF",
}: Props) => {
  return (
    <div className={styles.container} style={{ color, backgroundColor }}>
      <div className={styles.icon}>{icon}</div>
      <span className={styles.content}>{text}</span>
    </div>
  );
};
