import { ObserverCriteria, ObserverPageDto, PagedResponse } from "types";
import { useQuery } from "react-query";
import { ClientApi } from "services/ClientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "config";

interface UseGetObserversProps {
  params: ObserverCriteria;
}

export const useGetObservers = ({ params }: UseGetObserversProps) => {
  return useQuery<PagedResponse<ObserverPageDto>>({
    queryKey: [QUERY_KEYS.observers, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObservers(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
