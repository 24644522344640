import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const FreshnessIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          d="M11.7502 3.66702C11.846 3.4274 11.6695 3.16675 11.4115 3.16675H7.10619C6.94917 3.16675 6.80978 3.26722 6.76012 3.41618L4.81459 9.25277C4.73586 9.489 4.91167 9.7329 5.16066 9.7329H7.10563C7.34295 9.7329 7.51708 9.95593 7.45952 10.1862L6.43311 14.2918C6.34338 14.6507 6.78334 14.8998 7.04495 14.6383L13.5163 8.16695C13.7461 7.93713 13.5833 7.54418 13.2583 7.54418H10.7381C10.48 7.54418 10.3035 7.28354 10.3994 7.04392L11.7502 3.66702Z"
          stroke={color}
        />
      </svg>
    );
  },
);
