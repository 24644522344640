import React from "react";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResourceType } from "../../types";
import {
  Button,
  ButtonLabel,
  Form,
  FormFieldInput,
  FormFieldSelect,
} from "../../_ui";
import { schema } from "./utils";
import styles from "./ResourceCreationForm.module.css";
import { ResourceFormData } from "./types";

interface Props {
  onSubmit: SubmitHandler<ResourceFormData>;
}

export const ResourceCreationForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResourceFormData>({
    resolver: yupResolver(schema) as Resolver<ResourceFormData>,
    defaultValues: {
      host: "",
      port: 0,
      username: "",
      password: "",
      dbName: "",
      schema: "",
      type: ResourceType.POSTGRESQL,
      name: "",
    },
  });
  const handleOnSubmit = (data: ResourceFormData) => {
    onSubmit(data);
  };

  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className={styles.formField}>
          <FormFieldInput
            type="text"
            control={control}
            name="name"
            label="Name"
            errorMessage={errors.name?.message}
          />
        </div>
        <div className={styles.formField}>
          <FormFieldSelect
            variant="outlined"
            placeholder="Type"
            control={control}
            name="type"
            label="Type"
            options={Object.values(ResourceType).map((unit) => ({
              value: unit,
              label: unit,
            }))}
            errorMessage={errors.type?.message}
          />
        </div>
        <div className={styles.host}>
          <div className={styles.spcField}>
            <FormFieldInput
              type="text"
              control={control}
              name="host"
              label="Host"
              errorMessage={errors.host?.message}
            />
          </div>
          <div className={styles.spcField}>
            <FormFieldInput
              type="text"
              control={control}
              name="port"
              label="Port"
              errorMessage={errors.port?.message}
            />
          </div>
        </div>
        <div className={styles.formField}>
          <FormFieldInput
            type="text"
            control={control}
            name="dbName"
            label="Database Name"
            errorMessage={errors.dbName?.message}
          />
        </div>
        <div className={styles.formField}>
          <FormFieldInput
            type="text"
            control={control}
            name="username"
            label="Username"
            errorMessage={errors.username?.message}
          />
        </div>
        <div className={styles.formField}>
          <FormFieldInput
            type="password"
            control={control}
            name="password"
            label="Password"
            errorMessage={errors.password?.message}
          />
        </div>
        <div className={styles.formField}>
          <FormFieldInput
            type="text"
            control={control}
            name="schema"
            label="Schema"
            errorMessage={errors.schema?.message}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </Form>
    </div>
  );
};
