import React from "react";
import { ObserverVolumeCreationContainer } from "containers";

export const ObserverVolumeCreationPage = () => {
  return (
    <div>
      <ObserverVolumeCreationContainer />
    </div>
  );
};
