import {
  Badge,
  DataQualityIcon,
  FailedRunIcon,
  FreshnessIcon,
  PassedRunIcon,
  RunningIcon,
  SchemaIcon,
  StateIcon,
  VolumeIcon,
  WarningIcon,
} from "../_ui";
import React from "react";

export const observerIcons: { [key: string]: JSX.Element } = {
  FRESHNESS: (
    <Badge
      icon={<FreshnessIcon color={"#2D1DB4"} />}
      text="freshness"
      color="#2D1DB4"
    />
  ),
  VOLUME: (
    <Badge
      icon={<VolumeIcon color={"#2D1DB4"} />}
      text="volume"
      color="#2D1DB4"
    />
  ),
  SCHEMA: (
    <Badge
      icon={<SchemaIcon color={"#2D1DB4"} />}
      text="schema"
      color="#2D1DB4"
    />
  ),
  DATAQUALITY: (
    <Badge
      icon={<DataQualityIcon color={"#2D1DB4"} />}
      text="data quality"
      color="#2D1DB4"
    />
  ),
};
export const runsIcons: { [key: string]: JSX.Element } = {
  FAILED: (
    <Badge
      icon={<FailedRunIcon color={"#FB2942"} />}
      text="Failed"
      backgroundColor="#FFFFFF"
      color="#FB2942"
    />
  ),
  PASSED: (
    <Badge
      icon={<PassedRunIcon color={"#59BE48"} />}
      text="Passed"
      color="#59BE48"
      backgroundColor="#FFFFFF"
    />
  ),
  WARNING: (
    <Badge
      icon={<WarningIcon color={"#EFDD3B"} />}
      text="Warning"
      color="#EFDD3B"
      backgroundColor="#FFFFFF"
    />
  ),
  RUNNING: (
    <Badge
      icon={<RunningIcon color={"#FF9800"} />}
      text="Running"
      color="#FF9800"
      backgroundColor="#FFFFFF"
    />
  ),
};
export const observerState: { [key: string]: JSX.Element } = {
  ENABLED: (
    <Badge
      icon={<StateIcon color={"#41B06E"} />}
      text="Enabled"
      color="#41B06E"
      backgroundColor="#FFFFFF"
    />
  ),
  DISABLED: (
    <Badge
      icon={<StateIcon color={"#979797"} />}
      text="Enabled"
      color="#979797"
      backgroundColor="#FFFFFF"
    />
  ),
};
