import React from "react";
import styles from "./CustomCard.module.css";
import { Card, CardBody, CardHeader, CardTitle } from "../../_ui";

interface CustomCardProps {
  title: string;
  icons: React.ReactNode[];
  phrases: string[];
  values: string[];
  redValues: boolean;
}

export const ObserverOverviewCard: React.FC<CustomCardProps> = ({
  title,
  icons,
  phrases,
  values,
  redValues,
}) => (
  <Card className={styles.card}>
    <CardHeader>
      <CardTitle className={styles.cardTitle}>{title}</CardTitle>
    </CardHeader>
    <CardBody className={styles.cardBody}>
      <div className={styles.line}>
        <div className={styles.cardRow}>
          <div className={styles.icon}>{icons[0]}</div>
          <p>
            {phrases[0]}{" "}
            {values[0] == "0" ? (
              <span>0</span>
            ) : (
              <span className={redValues ? styles.redValues : ""}>
                {values[0]}
              </span>
            )}{" "}
          </p>
        </div>
        <div className={styles.cardRow}>
          <div className={styles.icon}>{icons[1]}</div>
          <p>
            {phrases[1]}{" "}
            {values[1] == "0" ? (
              <span>0</span>
            ) : (
              <span className={redValues ? styles.redValues : ""}>
                {values[1]}
              </span>
            )}{" "}
          </p>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.cardRow}>
          <div className={styles.icon}>{icons[2]}</div>
          <p>
            {phrases[2]}{" "}
            {values[2] == "0" ? (
              <span>0</span>
            ) : (
              <span className={redValues ? styles.redValues : ""}>
                {values[2]}
              </span>
            )}{" "}
          </p>
        </div>
        <div className={styles.cardRow}>
          <div className={styles.icon}>{icons[3]}</div>
          <p>
            {phrases[3]}{" "}
            {values[3] == "0" ? (
              <span>0</span>
            ) : (
              <span className={redValues ? styles.redValues : ""}>
                {values[3]}
              </span>
            )}{" "}
          </p>
        </div>
      </div>
    </CardBody>
  </Card>
);
