import React from "react";
import {
  TabItem as TabItemPrimary,
  TabItemProps as TabItemPrimaryProps,
} from "@nemo-ui/design-system.ui.tabs";
import clsx from "clsx";
import styles from "./TabItem.module.css";

interface TabItemProps extends TabItemPrimaryProps {
  className?: string;
}

export const TabItem = (props: TabItemProps) => {
  return (
    <TabItemPrimary
      {...props}
      className={clsx(
        "nemo-tab-item",
        styles.tab_item,
        props.active && styles.tab_item_active,
        props.className,
      )}
    ></TabItemPrimary>
  );
};
