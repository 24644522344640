import React from "react";
import {
  TableHead as TableHeadPrimitive,
  TableHeadProps as TableHeadPrimitiveProps,
} from "@nemo-ui/design-system.ui.table";

interface TableHeadProps extends TableHeadPrimitiveProps {}

export const TableHead = (props: TableHeadProps) => {
  return <TableHeadPrimitive {...props}>{props.children}</TableHeadPrimitive>;
};
