import React from "react";
import {
  Button as ButtonPrimitive,
  ButtonProps as ButtonPropsPrimitive,
} from "@nemo-ui/design-system.ui.button";

interface ButtonProps extends ButtonPropsPrimitive {}

export const Button = (props: ButtonProps) => {
  return <ButtonPrimitive {...props} />;
};
