import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { VolumeObserverCreationDto } from "types";

export const useCreateVolumeObserver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createVolumeObserver],
    mutationFn: (volumeDto: VolumeObserverCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.createVolumeObservers(), volumeDto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.observers], {
        exact: false,
      });
    },
  });
};
