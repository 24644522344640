import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ObserverFilters, ObserverType, StatusType } from "../../types";
import { parsePageNumber } from "../../utils";
import { useGetObserverLog } from "../../services";
import styles from "../RunsListingsContainer/RunsListingContainer.module.css";
import {
  ObserverDetailsSearchSection,
  ObserverLogDataTable,
} from "../../components";
import { API_LOGS_PAGE_SIZE_DEFAULT } from "../../constants";

const OBSERVER_SEARCH_CRITERIA_DEFAULT = {
  page: 0,
  size: API_LOGS_PAGE_SIZE_DEFAULT,
};

export const ObserverDetailsRunsListingContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilter] = useState<ObserverFilters>({
    searchQuery: "",
    searchType: "",
    searchTableName: "",
    searchStateType: "",
  });

  const currentPage = parsePageNumber(searchParams.get("page"));
  const { observerId } = useParams();

  const {
    data: observerLogs,
    isLoading,
    isFetching,
  } = useGetObserverLog({
    params: {
      ...OBSERVER_SEARCH_CRITERIA_DEFAULT,
      name: filters.searchQuery,
      type: filters.searchType as ObserverType,
      resourceName: filters.searchTableName,
      status: filters.searchStateType as StatusType,
      page: currentPage,
      sortBy: "timestamp",
      sortDirection: "DESC",
    },
    observerId: observerId as string,
  });
  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  const handleChangeFilters = (newFilters: Partial<ObserverFilters>) => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };
  const handleOnReset = () => {
    setFilter({
      searchQuery: "",
      searchType: "",
      searchTableName: "",
      searchStateType: "",
    });
  };

  useEffect(() => {
    setSearchParams({
      page: (currentPage + 1).toString(),
      searchQuery: filters.searchQuery,
      searchType: filters.searchType,
      searchTableName: filters.searchTableName,
      searchStateType: filters.searchStateType,
    });
  }, [filters, currentPage, setSearchParams]);

  return (
    <div className={styles.container}>
      <ObserverDetailsSearchSection
        filters={filters}
        onChange={handleChangeFilters}
        typeSpecification={StatusType}
        typeOfSource="resource"
        handleReset={handleOnReset}
      />
      <ObserverLogDataTable
        observerLogs={observerLogs && observerLogs.content}
        isFetching={isFetching}
        isLoading={isLoading}
        currentPage={currentPage}
        totalPages={observerLogs && observerLogs.totalPages}
        totalElements={observerLogs && observerLogs.totalElements}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
};
