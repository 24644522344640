import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const DotsHorizontalIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M11.6667 4.16667C11.6667 5.08714 10.9205 5.83333 10 5.83333C9.07954 5.83333 8.33337 5.08714 8.33337 4.16667C8.33337 3.24619 9.07954 2.5 10 2.5C10.9205 2.5 11.6667 3.24619 11.6667 4.16667Z"
          fill={color}
        />
        <path
          d="M11.6667 9.99992C11.6667 10.9204 10.9205 11.6666 10 11.6666C9.07954 11.6666 8.33337 10.9204 8.33337 9.99992C8.33337 9.07942 9.07954 8.33325 10 8.33325C10.9205 8.33325 11.6667 9.07942 11.6667 9.99992Z"
          fill={color}
        />
        <path
          d="M10 17.5001C10.9205 17.5001 11.6667 16.7539 11.6667 15.8334C11.6667 14.9129 10.9205 14.1667 10 14.1667C9.07954 14.1667 8.33337 14.9129 8.33337 15.8334C8.33337 16.7539 9.07954 17.5001 10 17.5001Z"
          fill={color}
        />
      </svg>
    );
  },
);
