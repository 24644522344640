import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const QupidLogo = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="102"
        height="32"
        viewBox="0 0 102 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          d="M24.5039 14.6142C24.5039 20.7372 19.5403 25.7008 13.4173 25.7008C7.29436 25.7008 2.33072 20.7372 2.33072 14.6142C2.33072 8.49123 7.29436 3.52759 13.4173 3.52759C19.5403 3.52759 24.5039 8.49123 24.5039 14.6142Z"
          fill="#DCBFFF"
        />
        <path
          d="M33.3228 24.063C33.3228 26.4983 31.3487 28.4725 28.9134 28.4725C26.4781 28.4725 24.5039 26.4983 24.5039 24.063C24.5039 21.6278 26.4781 19.6536 28.9134 19.6536C31.3487 19.6536 33.3228 21.6278 33.3228 24.063Z"
          fill="#DCBFFF"
        />
        <path
          d="M78.0765 25.7009H75.1738V18.0733H78.27C79.2053 18.0733 80.017 18.2238 80.705 18.5248C81.4038 18.8151 81.9413 19.2398 82.3176 19.7988C82.7046 20.3471 82.8982 21.0136 82.8982 21.7984C82.8982 22.5832 82.6993 23.2713 82.3015 23.8625C81.9145 24.4431 81.3608 24.8946 80.6405 25.2171C79.9202 25.5396 79.0655 25.7009 78.0765 25.7009ZM79.9632 21.9597C79.9632 21.5619 79.8987 21.2179 79.7697 20.9276C79.6515 20.6373 79.4633 20.417 79.2053 20.2664C78.958 20.1159 78.6355 20.0407 78.2377 20.0407H77.8668V23.7335H78.3668C78.7323 23.7335 79.0333 23.669 79.2698 23.54C79.5063 23.4003 79.6783 23.196 79.7858 22.9272C79.9041 22.6585 79.9632 22.3359 79.9632 21.9597Z"
          fill="#DCBFFF"
        />
        <path
          d="M70.0418 25.7009V18.0733H73.0251V25.7009H70.0418Z"
          fill="#DCBFFF"
        />
        <path
          d="M61.3628 25.7009V18.0733H64.9266C65.7222 18.0733 66.3833 18.1916 66.9101 18.4281C67.4477 18.6538 67.8508 18.9764 68.1196 19.3956C68.3991 19.8042 68.5388 20.2826 68.5388 20.8309C68.5388 21.3899 68.3883 21.9167 68.0873 22.4112C67.7971 22.9057 67.3617 23.3035 66.7811 23.6045C66.2113 23.9055 65.5125 24.0561 64.6847 24.0561H62.7819V21.9597H64.846C65.2223 21.9597 65.5125 21.879 65.7168 21.7178C65.9318 21.5458 66.0393 21.3146 66.0393 21.0244C66.0393 20.7556 65.9372 20.5352 65.7329 20.3632C65.5287 20.1912 65.2276 20.1052 64.8299 20.1052H64.0236V25.7009H61.3628Z"
          fill="#DCBFFF"
        />
        <path
          d="M55.4294 25.8622C54.6661 25.8622 53.9996 25.7278 53.4298 25.459C52.8708 25.1795 52.4407 24.7925 52.1397 24.2979C51.8387 23.7927 51.6882 23.196 51.6882 22.508V18.0733H54.6231V22.6208C54.6231 23.0294 54.7253 23.3519 54.9295 23.5884C55.1445 23.8142 55.4241 23.927 55.7681 23.927C56.1013 23.927 56.3594 23.8249 56.5421 23.6207C56.7356 23.4056 56.8324 23.11 56.8324 22.7337V18.0733H59.5416V22.0403C59.5416 22.8251 59.3749 23.5024 59.0416 24.0722C58.7191 24.642 58.2515 25.0827 57.6387 25.3945C57.0259 25.7063 56.2895 25.8622 55.4294 25.8622Z"
          fill="#DCBFFF"
        />
        <path
          d="M45.7842 25.8622C44.9349 25.8622 44.1877 25.6955 43.5427 25.3623C42.8977 25.029 42.3924 24.5721 42.0268 23.9916C41.6721 23.411 41.4947 22.7445 41.4947 21.9919C41.4947 21.1964 41.6775 20.4922 42.043 19.8794C42.4192 19.2666 42.946 18.7882 43.6233 18.4442C44.3006 18.0894 45.0854 17.9121 45.9777 17.9121C46.827 17.9121 47.5742 18.0787 48.2192 18.412C48.8643 18.7345 49.3642 19.1914 49.7189 19.7827C50.0845 20.374 50.2672 21.0566 50.2672 21.8307C50.2672 22.637 50.0737 23.3411 49.6867 23.9432C49.3104 24.5452 48.7836 25.0182 48.1063 25.3623C47.4398 25.6955 46.6658 25.8622 45.7842 25.8622ZM45.9455 23.9109C46.2035 23.9109 46.4185 23.8464 46.5905 23.7174C46.7733 23.5777 46.9076 23.368 46.9937 23.0885C47.0797 22.7982 47.1227 22.4273 47.1227 21.9758C47.1227 21.492 47.0743 21.0942 46.9775 20.7825C46.8808 20.4707 46.7356 20.2396 46.5421 20.0891C46.3486 19.9278 46.1067 19.8472 45.8165 19.8472C45.5584 19.8472 45.3434 19.9171 45.1714 20.0568C44.9994 20.1858 44.865 20.3955 44.7683 20.6857C44.6823 20.9652 44.6393 21.3308 44.6393 21.7823C44.6393 22.2661 44.6876 22.6692 44.7844 22.9917C44.8919 23.3035 45.0424 23.5346 45.2359 23.6852C45.4294 23.8357 45.6659 23.9109 45.9455 23.9109ZM49.6222 28.4907C48.7729 28.4907 48.0042 28.2596 47.3162 27.7973C46.6389 27.3458 46.0046 26.647 45.4133 25.7009L48.0257 24.7656C48.3805 25.3461 48.7406 25.8245 49.1062 26.2008C49.4824 26.5878 49.9125 26.7813 50.3962 26.7813C50.4715 26.7813 50.5682 26.7706 50.6865 26.7491C50.8048 26.7276 50.9338 26.69 51.0735 26.6362L51.6218 27.9747C51.224 28.1897 50.8478 28.3294 50.493 28.3939C50.1382 28.4584 49.848 28.4907 49.6222 28.4907Z"
          fill="#DCBFFF"
        />
        <path
          d="M75.7212 14.7756C74.8719 14.7756 74.1247 14.6089 73.4797 14.2756C72.8347 13.9424 72.3294 13.4855 71.9639 12.9049C71.6091 12.3244 71.4317 11.6579 71.4317 10.9053C71.4317 10.1098 71.6145 9.4056 71.98 8.79281C72.3563 8.18002 72.883 7.70162 73.5603 7.3576C74.2376 7.00283 75.0224 6.82544 75.9147 6.82544C76.764 6.82544 77.5112 6.99208 78.1562 7.32535C78.8013 7.64786 79.3012 8.10477 79.656 8.69605C80.0215 9.28734 80.2042 9.97001 80.2042 10.7441C80.2042 11.5504 80.0107 12.2545 79.6237 12.8566C79.2474 13.4586 78.7206 13.9316 78.0434 14.2756C77.3768 14.6089 76.6028 14.7756 75.7212 14.7756ZM75.8825 12.8243C76.1405 12.8243 76.3555 12.7598 76.5275 12.6308C76.7103 12.491 76.8447 12.2814 76.9307 12.0019C77.0167 11.7116 77.0597 11.3407 77.0597 10.8892C77.0597 10.4054 77.0113 10.0076 76.9145 9.69587C76.8178 9.3841 76.6726 9.15296 76.4791 9.00245C76.2856 8.84119 76.0437 8.76056 75.7535 8.76056C75.4954 8.76056 75.2804 8.83044 75.1084 8.9702C74.9364 9.0992 74.802 9.30884 74.7053 9.59911C74.6193 9.87863 74.5763 10.2441 74.5763 10.6957C74.5763 11.1795 74.6246 11.5826 74.7214 11.9051C74.8289 12.2169 74.9794 12.448 75.1729 12.5985C75.3664 12.7491 75.603 12.8243 75.8825 12.8243Z"
          fill="#DCBFFF"
        />
        <path
          d="M67.1065 14.6143L66.9453 8.42191L67.6064 8.13164L65.3327 14.1144H63.7846L61.2044 7.59949L62.0591 6.9867H63.2363L65.3972 11.5987H64.3329L66.397 6.9867H69.3803L69.8802 14.6143H67.1065ZM59.8176 14.6143L60.2046 6.9867H62.1236L61.9785 14.6143H59.8176Z"
          fill="#DCBFFF"
        />
        <path
          d="M51.914 14.6143V6.9867H54.6231V14.6143H51.914ZM52.9783 14.6143V12.8404H58.2999V14.6143H52.9783ZM52.9783 11.7116V9.92163H57.4452V11.7116H52.9783ZM52.9783 8.80894V6.9867H58.0902V8.80894H52.9783Z"
          fill="#DCBFFF"
        />
        <path
          d="M41.914 14.6143V6.9867H44.091L44.4457 14.6143H41.914ZM48.1708 14.6143H47.1388L43.0105 8.61542V6.9867H44.5264L48.0418 12.018L48.1708 14.6143ZM49.8479 6.9867V14.6143H47.5581L47.3162 6.9867H49.8479Z"
          fill="#DCBFFF"
        />
      </svg>
    );
  },
);
