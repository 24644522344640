import { useQuery } from "react-query";
import { ClientApi } from "services";
import { API_ENDPOINTS, QUERY_KEYS } from "config";
import { ObserverStatistics } from "../../types";

export const useGetObserverStatistics = () => {
  return useQuery<ObserverStatistics>({
    queryKey: [QUERY_KEYS.statistics],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getObserverStatistics());
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
