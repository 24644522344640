import React from "react";
import {
  ComplianceObserverRuleData,
  ComplianceObserverRuleFormData,
} from "./types";
import { Resolver, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonLabel, Card, CardBody, Form, FormFieldInput } from "_ui";
import styles from "./ObserverComplianceRuleForm.module.css";
import { schema } from "./utils";
import { ComplianceObserverCreationFormData } from "../../../containers/ObserverComplianceCreationContainer/types";

interface Props {
  onSubmit: (data: ComplianceObserverRuleData) => void;
  onClickBackButton: () => void;
  formData?: ComplianceObserverCreationFormData;
}

export const ObserverComplianceRuleForm: React.FC<Props> = ({
  onSubmit,
  onClickBackButton,
  formData,
}) => {
  // Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ComplianceObserverRuleFormData>({
    resolver: yupResolver(
      schema,
    ) as unknown as Resolver<ComplianceObserverRuleFormData>,
    defaultValues: {
      columnName: formData?.columnName,
      errorIf: formData?.errorIf,
      warnIf: formData?.warnIf,
      values: [{ value: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "values",
  });

  // Event handlers
  const handleOnSubmit = (data: ComplianceObserverRuleFormData) => {
    const transformedData: ComplianceObserverRuleData = {
      ...data,
      values: data.values.map((item) => item.value),
    };
    onSubmit(transformedData);
  };

  const handleBack = () => {
    onClickBackButton();
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name="columnName"
            label="Column name"
            type="text"
            fullWidth
            errorMessage={errors.columnName?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name="errorIf"
            label="Error If"
            type="number"
            fullWidth
            errorMessage={errors.errorIf?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name="warnIf"
            label="Warn If"
            type="number"
            fullWidth
            errorMessage={errors.warnIf?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={styles.labelValues}>Values</label>
          <Card className={styles.card}>
            <CardBody>
              {fields.map((field, index) => (
                <div key={field.id} className={styles.arrayFieldContainer}>
                  <div className={styles.inputValueContainer}>
                    <FormFieldInput
                      control={control}
                      name={
                        `values[${index}].value` as keyof ComplianceObserverRuleFormData
                      }
                      label={`Value ${index + 1}`}
                      type="text"
                      fullWidth
                      errorMessage={errors.values?.[index]?.value?.message}
                    />
                  </div>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => remove(index)}
                    className={styles.removeButton}
                  >
                    <ButtonLabel>Remove</ButtonLabel>
                  </Button>
                </div>
              ))}
              <div className={styles.addButtonContainer}>
                <Button
                  variant="neutral"
                  onClick={() => append({ value: "" })}
                  className={styles.addButton}
                >
                  <ButtonLabel>Add Value</ButtonLabel>
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.buttonGost}
            type="button"
            onClick={handleBack}
          >
            <ButtonLabel>Back</ButtonLabel>
          </button>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Form>
  );
};
