import React from "react";
import {
  CardHeader as CardHeaderPrimitive,
  CardHeaderProps as CardHeaderPropsPrimitive,
} from "@nemo-ui/design-system.ui.card";

interface CardHeaderProps extends CardHeaderPropsPrimitive {
  children?: React.ReactNode;
}

export const CardHeader = (props: CardHeaderProps) => {
  return <CardHeaderPrimitive {...props}>{props.children}</CardHeaderPrimitive>;
};
