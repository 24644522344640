import React from "react";
import { AddObserverIcon, Button, ButtonIcon, ButtonLabel, Heading } from "_ui";
import styles from "./ObserverHeader.module.css";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  headerTitle?: string;
  buttonTitle?: string;
}

export const Header: React.FC<Props> = ({ headerTitle, buttonTitle }) => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Event Handler
  const handleClick = () => {
    navigate(`${location.pathname}/create`);
  };

  return (
    <div className={styles.header}>
      <Heading level={3}>{headerTitle}</Heading>
      <Button className={styles.button} onClick={handleClick}>
        <ButtonIcon className={styles.icon}>
          {" "}
          <AddObserverIcon />
        </ButtonIcon>
        <ButtonLabel className={styles.buttonLabel}>{buttonTitle}</ButtonLabel>
      </Button>
    </div>
  );
};
