import { PropsWithChildren } from "react";
import {
  Text as TextPrimitive,
  TextProps as TextPrimitiveProps,
} from "@nemo-ui/design-system.ui.text";

interface TextProps extends TextPrimitiveProps {}

export const Text = ({ children, ...props }: PropsWithChildren<TextProps>) => {
  return <TextPrimitive {...props}>{children}</TextPrimitive>;
};
