import React from "react";
import styles from "./AlertDialog.module.css";
import { Button } from "../Button";

interface AlertDialogProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  message,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <p className={styles.title}>Are you sure ? </p>
        <p>{message}</p>
        <div className={styles.buttonContainer}>
          <Button
            variant={"neutral"}
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button className={styles.confirmButton} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
