import React, { ReactNode } from "react";
import styles from "./Form.module.css";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
}

export const Form: React.FC<Props> = ({ onSubmit, children }) => {
  return (
    <form className={styles.formContainer} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
