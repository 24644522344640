import React, { useState } from "react";
import styles from "./ObserverPreviousRuns.module.css";
import { ChevronRightIcon, FailedRunIcon, PassedRunIcon } from "_ui";
import { ObserverLogsPageDto, StatusType } from "types";

interface ObserverPreviousRunsProps {
  runs?: ObserverLogsPageDto[];
  setShowRuns?: (() => void) | undefined;
}

export const ObserverPreviousRuns = ({
  runs,
  setShowRuns,
}: ObserverPreviousRunsProps) => {
  const [displayedRuns, setDisplayedRuns] = useState(7);

  return (
    <>
      {runs && runs.length > 0 ? (
        <ul className={styles.table}>
          {runs.slice(0, displayedRuns).map((run, index) => {
            const isPassed = run.status === StatusType.PASSED;
            const date = new Date(run.timestamp);
            const formattedDate = date.toLocaleDateString("default", {
              month: "long",
              day: "numeric",
            });
            const formattedTime = date.toLocaleTimeString("default", {
              hour: "2-digit",
              minute: "2-digit",
            });

            return (
              <li key={index} className={styles.line}>
                <div className={styles.icon}>
                  {isPassed ? (
                    <PassedRunIcon color="#59BE48" />
                  ) : (
                    <FailedRunIcon color="#FB2942" />
                  )}
                </div>
                <div className={styles.details}>
                  {isPassed ? "Passed" : "Failed"} run on {formattedDate} at{" "}
                  {formattedTime}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div>No previous runs available</div>
      )}
      <div className={styles.more} onClick={setShowRuns}>
        SEE MORE
        <div className={styles.icon}>
          <ChevronRightIcon strokeWidth={1} />
        </div>
      </div>
    </>
  );
};
