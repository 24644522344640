import React from "react";
import {
  CardTitle as CardTitlePrimitive,
  CardTitleProps as CardTitlePropsPrimitive,
} from "@nemo-ui/design-system.ui.card";

interface CardTitleProps extends CardTitlePropsPrimitive {
  children?: React.ReactNode;
}

export const CardTitle = (props: CardTitleProps) => {
  return <CardTitlePrimitive {...props}>{props.children}</CardTitlePrimitive>;
};
