import React from "react";
import { Table as TablePrimitive } from "@nemo-ui/design-system.ui.table";

interface TableProps {
  className?: string;
  children?: React.ReactNode;
}

export const Table = (props: TableProps) => {
  return <TablePrimitive {...props}>{props.children}</TablePrimitive>;
};
