import React from "react";
import { ObserverLogsPageDto } from "types";
import { TableCell, TableRow } from "_ui";
import { formatDate, observerIcons, runsIcons } from "utils";
import styles from "./ObserverLogsDataTableRow.module.css";

interface ObserverLogsDataTableRowProps {
  observerLogs: ObserverLogsPageDto;
}

export const ObserverLogDataTableRow = (
  props: ObserverLogsDataTableRowProps,
) => {
  return (
    <TableRow>
      <TableCell>
        {runsIcons[props.observerLogs.status] &&
          React.cloneElement(runsIcons[props.observerLogs.status])}
      </TableCell>
      <TableCell>
        <span className={styles.name}>
          {props.observerLogs.observerRepresentation.name.toUpperCase()}
        </span>
      </TableCell>
      <TableCell>
        {observerIcons[props.observerLogs.observerRepresentation.type] &&
          React.cloneElement(
            observerIcons[props.observerLogs.observerRepresentation.type],
          )}
      </TableCell>
      <TableCell className={styles.source}>
        {props.observerLogs.observerRepresentation.resourceName.toLowerCase()}
      </TableCell>
      <TableCell>
        {formatDate(props.observerLogs.timestamp).toLowerCase()}
      </TableCell>
      <TableCell align={"right"}></TableCell>
    </TableRow>
  );
};
