import React, { useState } from "react";
import { ObserverType, StateType } from "types";
import { SubmitHandler } from "react-hook-form";
import { Alert } from "_ui";
import {
  ObserverHeaderCreation,
  ObserverInfoForm,
  ObserverSchedulerForm,
  ObserverVolumeRuleForm,
  ProgressStepper,
} from "components";
import { useCreateVolumeObserver } from "services";
import {
  OrderedStep,
  OrderedVolumeObserverCreationSteps,
  VolumeObserverCreationFormData,
} from "./types";
import styles from "./ObserverVolumeCreationContainer.module.css";
import { useNavigate } from "react-router-dom";

export const ObserverVolumeCreationContainer = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const { mutate } = useCreateVolumeObserver();
  const [currentStep, setCurrentStep] = useState<OrderedStep>(
    OrderedVolumeObserverCreationSteps[0],
  );
  const [formData, setFormData] = useState<VolumeObserverCreationFormData>({
    name: "",
    tableName: "",
    description: "",
    resourceName: "",
    state: StateType.ENABLED,
    timestamp: "",
    alertDetectionPeriod: { unit: null, value: 0 },
    startingTimeScheduler: { value: 0, unit: null, date: "" },
    maxRowExpected: 0,
    minRowExpected: 0,
  });

  const onSubmit: SubmitHandler<VolumeObserverCreationFormData> = async (
    data,
  ) => {
    const formDataWithObserverType = {
      ...data,
      type: ObserverType.VOLUME,
    };
    mutate(formDataWithObserverType, {
      onSuccess: (response) => {
        setAlertType("success");
        setAlertMessage("Observer creation successful!");
        setShowAlert(true);
        setTimeout(() => {
          navigate("/observers");
        }, 3000);
      },
      onError: (error) => {
        console.error("Error:", error);
        setAlertType("error");
        setAlertMessage("Error occurred during observer creation.");
        setShowAlert(true);
      },
    });
  };

  const handleOnPreviousStep = () => {
    setCurrentStep(OrderedVolumeObserverCreationSteps[currentStep.step - 2]);
  };

  const handleSubmitStep = (
    stepData: Partial<VolumeObserverCreationFormData>,
  ) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, ...stepData };

      if (currentStep.step === OrderedVolumeObserverCreationSteps.length) {
        onSubmit(updatedData);
      } else {
        setCurrentStep(OrderedVolumeObserverCreationSteps[currentStep.step]);
      }

      return updatedData;
    });
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading_wrapper}>
        <ObserverHeaderCreation name={"Create new volume observer"} />
      </div>

      <ProgressStepper
        steps={OrderedVolumeObserverCreationSteps}
        currentStep={currentStep.step}
        renderLabels={true}
      />
      {showAlert && (
        <Alert
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
        />
      )}
      {currentStep === OrderedVolumeObserverCreationSteps[0] && (
        <ObserverInfoForm
          onSubmit={(data) => handleSubmitStep(data)}
          formData={formData}
        />
      )}

      {currentStep === OrderedVolumeObserverCreationSteps[1] && (
        <ObserverVolumeRuleForm
          onSubmit={(data) => handleSubmitStep(data)}
          onClickBackButton={handleOnPreviousStep}
          formData={formData}
        />
      )}

      {currentStep === OrderedVolumeObserverCreationSteps[2] && (
        <ObserverSchedulerForm
          onSubmit={(data) => handleSubmitStep(data)}
          onClickBackButton={handleOnPreviousStep}
        />
      )}
    </div>
  );
};
