import { PagedResponse, ResourceCriteria, ResourceDto } from "types";
import { useQuery } from "react-query";
import { ClientApi } from "services/ClientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "config";

interface UseGetResourcePageProps {
  params: ResourceCriteria;
}

export const useGetResourcePage = ({ params }: UseGetResourcePageProps) => {
  return useQuery<PagedResponse<ResourceDto>>({
    queryKey: [QUERY_KEYS.resources, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getResourcesPage(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
