import React from "react";
import { ResourceListingContainer } from "../../containers";
import { Header } from "../../components";

export const ResourceListingPage = () => {
  return (
    <div>
      <Header headerTitle={"Resources"} buttonTitle={"Create connexion"} />
      <br />
      <ResourceListingContainer />
    </div>
  );
};
