import React, { forwardRef } from "react";
import { IconProps } from "_ui";

export const AddObserverIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <g>
          <path
            d="M18.75 15H16.25V12.5H15V15H12.5V16.25H15V18.75H16.25V16.25H18.75V15Z"
            fill="black"
          />
          <path
            d="M10 17.5H5V2.49997H10V6.24997C10.001 6.5812 10.133 6.89858 10.3672 7.1328C10.6014 7.36701 10.9188 7.49901 11.25 7.49997H15V9.99997H16.25V6.24997C16.2522 6.16783 16.2366 6.08617 16.2043 6.01064C16.1719 5.93511 16.1235 5.8675 16.0625 5.81247L11.6875 1.43747C11.6325 1.37643 11.5649 1.32805 11.4893 1.29568C11.4138 1.2633 11.3321 1.24771 11.25 1.24997H5C4.66877 1.25093 4.35139 1.38293 4.11717 1.61714C3.88296 1.85136 3.75096 2.16874 3.75 2.49997V17.5C3.75096 17.8312 3.88296 18.1486 4.11717 18.3828C4.35139 18.617 4.66877 18.749 5 18.75H10V17.5ZM11.25 2.74997L14.75 6.24997H11.25V2.74997Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_417_436">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
