import React from "react";
import { DataQualityIcon, FreshnessIcon, SchemaIcon, VolumeIcon } from "_ui";
import { ObserverOverviewCard } from "components/index";
import styles from "./CardSection.module.css";
import { useGetObserverStatistics } from "../../services";

export const ObserversOverviewSection = () => {
  const icons = [
    <VolumeIcon key="v1" />,
    <SchemaIcon key="s1" />,
    <FreshnessIcon color={"#44406E"} key="f1" />,
    <DataQualityIcon key="d1" />,
  ];
  const observers = [
    "volume observer: ",
    "schema observer: ",
    "Freshness observer: ",
    "quality observer: ",
  ];
  const data = ["data source: ", "columns: ", "tables: ", "rows: "];
  const failedRuns = ["volume: ", "schema: ", "freshness: ", "quality: "];

  //hook
  const { data: values, isLoading, isError } = useGetObserverStatistics();

  const observersValue = [
    values?.volumeObserverNumber.toString() || "0",
    values?.schemaObserverNumber.toString() || "0",
    values?.freshnessObserverNumber.toString() || "0",
    values?.dataQualityObserverNumber.toString() || "0",
  ];
  const dataValue = [
    values?.dataSourceNumber.toString() || "0",
    "0",
    values?.tablesNumber.toString() || "0",
    "0",
  ];
  const failedObsValue = [
    values?.failedVolumeObserverNumber == 0
      ? "0"
      : values?.failedVolumeObserverNumber.toString() + " failed runs" || "0",
    values?.failedSchemaObserverNumber == 0
      ? "0"
      : values?.failedSchemaObserverNumber.toString() + " failed runs" || "0",
    values?.failedFreshnessObserverNumber == 0
      ? "0"
      : values?.failedFreshnessObserverNumber.toString() + " failed runs" ||
        "0",
    values?.failedDataQualityObserverNumber == 0
      ? "0"
      : values?.failedDataQualityObserverNumber.toString() + " failed runs" ||
        "0",
  ];

  return (
    <div className={styles.container}>
      {isError && <div>Error fetching data...</div>}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <ObserverOverviewCard
            title="Observers"
            icons={icons}
            phrases={observers}
            values={observersValue}
            redValues={false}
          />
          <ObserverOverviewCard
            title="Data"
            icons={[]}
            phrases={data}
            values={dataValue}
            redValues={false}
          />
          <ObserverOverviewCard
            title="Failed observers"
            icons={icons}
            phrases={failedRuns}
            values={failedObsValue}
            redValues={true}
          />
        </>
      )}
    </div>
  );
};
