import { forwardRef } from "react";
import { IconProps } from "_ui";

export const PassedRunIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          d="M0 5C0 2.23857 2.23857 0 5 0C7.7614 0 10 2.23857 10 5C10 7.7614 7.7614 10 5 10C2.23857 10 0 7.7614 0 5ZM6.85355 3.64644C7.0488 3.84171 7.0488 4.1583 6.85355 4.35355L5.01215 6.19495C4.7293 6.4778 4.2707 6.4778 3.98784 6.19495L3.14644 5.35355C2.95118 5.1583 2.95118 4.8417 3.14644 4.64645C3.34171 4.4512 3.65829 4.4512 3.85355 4.64645L4.5 5.2929L6.14645 3.64644C6.3417 3.45118 6.6583 3.45118 6.85355 3.64644Z"
          fill={color}
        />
      </svg>
    );
  },
);
