import { forwardRef } from "react";
import { IconProps } from "_ui";

export const StateIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <circle cx="5" cy="5" r="5" fill={color} />
      </svg>
    );
  },
);
