import { useQuery } from "react-query";
import { ClientApi } from "../ClientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "config";
import { ObserverStatsParams, VolumeStats } from "types";

interface Props {
  params: ObserverStatsParams;
}

export const useGetVolumeObserverStats = ({ params }: Props) => {
  return useQuery<VolumeStats>({
    queryKey: [QUERY_KEYS.volumeLogsStats, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getVolumeObserverLogsStats(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
