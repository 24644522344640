import axios, { InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { REACT_APP_API_URL } from "config";
import { getAccessToken } from "auth";

export const ClientApi = axios.create({
  baseURL: REACT_APP_API_URL,
});

ClientApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const contentType = config.headers.get("Content-Type");
  config.headers.set("Accept", contentType || "application/json");
  config.headers.set("Content-Type", contentType || "application/json");
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return {
    ...config,
    paramsSerializer: {
      serialize: (p: object) => qs.stringify(p, { arrayFormat: "repeat" }),
    },
  };
});

ClientApi.interceptors.response.use((response) => {
  if (response.data) return response.data;
  return response;
});
