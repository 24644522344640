import React from "react";
import { PatternMatchObserverRuleFormData } from "./types";
import styles from "./ObserverPatternMatchRuleForm.module.css";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./utils";
import { Button, ButtonLabel, Form, FormFieldInput } from "../../../_ui";
import { PatternMatchObserverCreationFormData } from "../../../containers/ObserverPatternMatchCreationContainer/types";

interface Props {
  onSubmit: (data: PatternMatchObserverRuleFormData) => void;
  onClickBackButton: () => void;
  formData?: PatternMatchObserverCreationFormData;
}

export const ObserverPatternMatchRuleForm: React.FC<Props> = ({
  onSubmit,
  onClickBackButton,
  formData,
}) => {
  // Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PatternMatchObserverRuleFormData>({
    resolver: yupResolver(schema) as Resolver<PatternMatchObserverRuleFormData>,
    defaultValues: formData,
  });

  // Event handlers
  const handleOnSubmit = (data: PatternMatchObserverRuleFormData) => {
    onSubmit(data);
  };
  const handleBack = () => {
    onClickBackButton();
  };
  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"columnName"}
            label={"Column name"}
            type={"text"}
            fullWidth
            errorMessage={errors.columnName?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"errorIf"}
            label={"Error If"}
            type={"number"}
            fullWidth
            errorMessage={errors.errorIf?.message}
          />
        </div>

        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"warnIf"}
            label={"Warn If"}
            type={"number"}
            fullWidth
            errorMessage={errors.warnIf?.message}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormFieldInput
            control={control}
            name={"regex"}
            label={"Regex"}
            fullWidth
            errorMessage={errors.regex?.message}
          />
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.buttonGost} onClick={handleBack}>
            <ButtonLabel>Back</ButtonLabel>
          </button>
          <Button type="submit">
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Form>
  );
};
