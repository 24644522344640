import React from "react";
import {
  MenuItemLabel as MenuItemLabelPrimitive,
  MenuItemLabelProps as MenuItemLabelPrimitiveProps,
} from "@nemo-ui/design-system.ui.menu";

interface MenuItemLabelProps extends MenuItemLabelPrimitiveProps {
  children?: React.ReactNode;
}

export const MenuItemLabel = (props: MenuItemLabelProps) => {
  return (
    <MenuItemLabelPrimitive {...props}>{props.children}</MenuItemLabelPrimitive>
  );
};
