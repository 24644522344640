export const QUERY_KEYS = {
  observers: "observers",
  tableNames: "tableNames",
  statistics: "statistics",
  resources: "resources",
  observerLogs: "observerLogs",
  logsStats: "logsStats",
  volumeLogsStats: "volumeLogsStats",
  observer: "observer",
  observerLog: "observerLog",
};
export const MUTATION_KEYS = {
  createFreshnessObserver: "freshness-observer-create",
  deleteObserver: "freshness-observer-delete",
  updateFreshnessObserver: "freshness-observer-update",
  createVolumeObserver: "volume-observer-create",
  createSchemaObserver: "schema-observer-create",
  createResource: "resource-create",
  deleteResource: "resource-delete",
  updateResource: "resource-update",
  createComplianceObserver: "compliance-create",
  createPatternMatchObserver: "pattern-match-create",
  createQualityObserver: "quality-create",
  updateComplianceObserver: "compliance-update",
  updatePatternMatchObserver: "pattern-match-update",
  updateQualityObserver: "quality-update",
};
