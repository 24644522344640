import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";
import { ComplianceObserverCommand } from "types";

export const useCreateComplianceObserver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createComplianceObserver],
    mutationFn: (complianceObserverCommand: ComplianceObserverCommand) => {
      return ClientApi.post(
        API_ENDPOINTS.createComplianceObservers(),
        complianceObserverCommand,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.observers], {
        exact: false,
      });
    },
  });
};
