import React from "react";
import { ObserverPageDto, ObserverType } from "types";
import styles from "./ObserversDataTable.module.css";
import {
  Card,
  Empty,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_ui";
import { ObserversDataTableRow } from "components/index";

interface Props {
  observers?: ObserverPageDto[];
  handleUpdate: (observerId: string) => void;
  handleDelete: (observerId: string) => void;
  isFetching: boolean;
  isLoading: boolean;
  currentPage?: number;
  totalPages?: number;
  totalElements?: number;
  onPageChange: (page: number) => void;
  onObserverRowClick: (observerId: string, type: ObserverType) => void;
}

const headerCells = [
  "Type",
  "Name",
  "State",
  "Source",
  "Failed Runs",
  "Passed RUNS",
  "Runs",
  "Actions",
];
export const ObserversDataTable: React.FC<Props> = ({
  observers = [],
  handleUpdate,
  handleDelete,
  isLoading,
  isFetching,
  currentPage = 0,
  totalPages = 1,
  totalElements,
  onPageChange,
  onObserverRowClick,
}) => {
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };
  const showPagination = totalPages > 1;

  if (isLoading) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <div>
            <Text size={"lg"}>Loading observers...</Text>
          </div>
        </Card>
      </div>
    );
  }
  if (!isLoading && observers && observers.length === 0) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <Empty message={"No observers found."} />
        </Card>
      </div>
    );
  }
  return (
    <div className={styles.tableContainer}>
      <Table>
        <TableHeader>
          {headerCells.map((cell) => (
            <TableHead key={cell}>{cell}</TableHead>
          ))}
        </TableHeader>
        <TableBody>
          {isFetching && <ProgressBar position={"absolute"} />}
          {observers.map((observer) => (
            <ObserversDataTableRow
              observer={observer}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              key={observer.id}
              onObserverRowClick={onObserverRowClick}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableFooterCell colSpan={headerCells.length}>
            <div className={styles.footer}>
              <Text>{totalElements} observers</Text>
              {showPagination && (
                <Pagination
                  totalPages={totalPages}
                  onPageChange={handleOnPageChange}
                  currentPage={currentPage}
                />
              )}
            </div>
          </TableFooterCell>
        </TableFooter>
      </Table>
    </div>
  );
};
