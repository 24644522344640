import React, { RefObject, useRef, useState } from "react";
import {
  DateRangePicker as DateRangePickerPrimitive,
  RangeKeyDict,
} from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ChevronDownIcon, Popover } from "_ui";
import styles from "./DateRangePicher.module.css";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";

interface Range {
  startDate?: Date;
  endDate?: Date;
  key: string;
}

interface DateRangePickerCompProps {
  startDate: Date;
  endDate: Date;
  onDateChange: (startDate: Date, endDate: Date) => void;
}

export const DateRangePicker: React.FC<DateRangePickerCompProps> = ({
  startDate,
  endDate,
  onDateChange,
}) => {
  const [range, setRange] = useState<Range[]>([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const refOne: RefObject<HTMLDivElement> = useRef(null);
  const calculateDifference = (start: Date, end: Date) => {
    const totalHours = differenceInHours(end, start);
    const days = differenceInDays(end, start);
    const hours = totalHours % 24;
    return `${days}d${hours}h`;
  };
  const difference = calculateDifference(
    range[0].startDate!,
    range[0].endDate!,
  );

  const handleDateChange = (item: RangeKeyDict) => {
    const newRange = [item.selection as Range];
    setRange(newRange);
    onDateChange(newRange[0].startDate!, newRange[0].endDate!);
  };

  return (
    <div className={styles.wrapper}>
      <Popover
        trigger={
          <div className={styles.inputContainer}>
            <div>
              <span className={styles.dateDifference}>{difference}</span>
              <span
                className={styles.date}
              >{`${format(range[0].startDate!, "ddMMM HH:mm")} - ${format(range[0].endDate!, "ddMMM HH:mm")}`}</span>
            </div>
            <ChevronDownIcon className={styles.dropdownIcon} />
          </div>
        }
      >
        <div ref={refOne}>
          <DateRangePickerPrimitive
            onChange={handleDateChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={2}
            direction="horizontal"
          />
        </div>
      </Popover>
    </div>
  );
};
