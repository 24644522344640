import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { FormFieldProps } from "./types";
import { Label, Select, Text } from "_ui";
import styles from "./FormFieldSelect.module.css";

export const FormFieldSelect = <FieldsType extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  options,
  variant = "default",
  errorMessage,
  onChange,
}: FormFieldProps<FieldsType>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div className={styles.wrapper}>
            {label && <Label>{label}</Label>}
            <Select
              defaultValue={field.value}
              placeholder={placeholder}
              onChange={(value) => {
                field.onChange(value);
                onChange && onChange(value);
              }}
              options={options}
              variant={variant}
            />
            {errorMessage && (
              <Text className={styles.error_message}>{errorMessage}</Text>
            )}
          </div>
        );
      }}
    />
  );
};
