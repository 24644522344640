import React from "react";
import { ObserverLogsPageDto } from "../../types";
import styles from "../ObserversDataTable/ObserversDataTable.module.css";
import {
  Card,
  Empty,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "../../_ui";
import { ObserverLogDataTableRow } from "../ObserverLogDataTableRow";

interface Props {
  observerLogs?: ObserverLogsPageDto[];
  isFetching: boolean;
  isLoading: boolean;
  currentPage?: number;
  totalPages?: number;
  totalElements?: number;
  onPageChange: (page: number) => void;
}

const headerCells = ["Status", "Name", "Type", "Source", "Execution date", ""];
export const ObserverLogDataTable: React.FC<Props> = ({
  observerLogs = [],
  isLoading,
  isFetching,
  currentPage = 0,
  totalPages = 1,
  totalElements,
  onPageChange,
}) => {
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };
  const showPagination = totalPages > 1;

  if (isLoading) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <div className={styles.loader_wrapper}>
            <Text size={"lg"}>Loading observer logs...</Text>
          </div>
        </Card>
      </div>
    );
  }
  if (!isLoading && observerLogs && observerLogs.length === 0) {
    return (
      <div className={styles.tableContainer}>
        <Card>
          <Empty message={"No observer logs found."} />
        </Card>
      </div>
    );
  }
  return (
    <div className={styles.tableContainer}>
      <Table>
        <TableHeader>
          {headerCells.map((cell) => (
            <TableHead key={cell}>{cell}</TableHead>
          ))}
        </TableHeader>
        <TableBody>
          {isFetching && <ProgressBar position={"absolute"} />}
          {observerLogs.map((observer) => (
            <ObserverLogDataTableRow
              observerLogs={observer}
              key={observer.id}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableFooterCell colSpan={headerCells.length}>
            <div className={styles.footer}>
              <Text>{totalElements} Runs</Text>
              {showPagination && (
                <Pagination
                  totalPages={totalPages}
                  onPageChange={handleOnPageChange}
                  currentPage={currentPage}
                />
              )}
            </div>
          </TableFooterCell>
        </TableFooter>
      </Table>
    </div>
  );
};
