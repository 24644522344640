import React from "react";
import { Card, CardBody, CardTitle } from "_ui";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ObserverCreationTypeChoiceContent.module.css";
import { CardContentCreation, ObserverGroup } from "./CardContentCreation";

export const ObserverCreationTypeChoiceContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.container}>
      {CardContentCreation.map((group: ObserverGroup) => (
        <div key={group.groupTitle} className={styles.group}>
          <h2 className={styles.groupTitle}>{group.groupTitle}</h2>
          <p className={styles.groupDescription}>{group.groupDescription}</p>
          <div className={styles.typesContainer}>
            {group.types.map((element) => (
              <Card
                className={styles.card}
                key={element.key}
                onClick={() =>
                  navigate(`${location.pathname}/${element.containerLink}`)
                }
              >
                <CardTitle className={styles.title}>
                  <span className={styles.icon}>{element.icon}</span>
                  <span className={styles.titleText}>{element.title}</span>
                </CardTitle>
                <CardBody className={styles.content}>
                  <p className={styles.phrase}>{element.firstPhrase}</p>
                  <p className={styles.phrase}>{element.secondPhrase}</p>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
