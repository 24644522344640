export const API_ENDPOINTS = {
  // FreshnessObservers
  createFreshnessObservers: () => `/api/freshness-observers`,
  updateFreshnessObservers: (observerId: string) =>
    `/api/freshness-observers/${observerId}`,

  //Observers
  getObservers: () => `/api/observers`,
  deleteObserver: (observerId: string) => `/api/observers/${observerId}`,
  getObserverTableNames: () => `/api/observers/tables`,
  getObserverStatistics: () => `/api/observers/statistics`,

  //Resource
  getResources: () => `/api/resources`,
  //Observer logs
  getObserverLogs: () => `/api/observer-logs`,
  getObserverLogsStats: () => `/api/observer-logs/stats`,

  //volume observer
  getVolumeObserverLogsStats: () => `/api/volume-observers/stats`,
  createVolumeObservers: () => `/api/volume-observers`,

  //schema observer
  createSchemaObservers: () => `api/schema-observers`,

  //resources
  createResource: () => `/api/resources`,
  getResourcesPage: () => `/api/resources/page`,
  deleteResource: (resourceId: string) => `api/resources/${resourceId}`,

  //data quality observers
  createComplianceObservers: () => `api/quality-observers/compliance`,
  createPatternMatchObservers: () => `api/quality-observers/pattern-match`,
  createQualityObservers: () => `api/quality-observers`,
  updateComplianceObservers: (observerId: string) =>
    `api/quality-observers/compliance/${observerId}`,
  updatePatternMatchObservers: (observerId: string) =>
    `api/quality-observers/pattern-match/${observerId}`,
  updateQualityObservers: (observerId: string) =>
    `api/quality-observers/${observerId}`,
  updateResource: (resourceId: string) => `/api/resources/${resourceId}`,
  getObserver: (observerId: string) => `/api/observers/${observerId}`,
  getObserverLog: (observerId: string) => `/api/observer-logs/${observerId}`,
};
