import React from "react";
import styles from "./ObserverListing.module.css";
import { Header } from "components";
import { ObserverTabsSwitchContainer } from "../../containers";

export const ObserversListingPage = () => {
  return (
    <div>
      <Header headerTitle={"Observers"} buttonTitle={"Create observer"} />
      <ObserverTabsSwitchContainer className={styles.content} />
    </div>
  );
};
