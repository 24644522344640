import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "config";
import { ClientApi } from "services/ClientApi";

export const useDeleteResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteResource],
    mutationFn: (observerId: string) => {
      return ClientApi.delete(API_ENDPOINTS.deleteResource(observerId));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.resources]);
      await queryClient.invalidateQueries([QUERY_KEYS.observers]);
      await queryClient.invalidateQueries([QUERY_KEYS.observerLogs]);
    },
  });
};
